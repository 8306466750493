import { collection, getDocs, query, where } from 'firebase/firestore';
import { firestore } from '../firebase';
import { Issue, Project, User } from '../types';

// Modify this type to include 'id'
type PartialUser = Pick<User, 'userId' | 'firstName' | 'lastName' | 'avatarUrl'> & { id: string };

export const fetchDashboardData = async () => {
  const projectsQuery = query(collection(firestore, 'projects'));
  const projectsSnapshot = await getDocs(projectsQuery);
  const totalProjects = projectsSnapshot.docs.filter(doc => {
    const projectData = doc.data() as Project;
    return !projectData.isArchived;
  }).length;

  const issuesQuery = query(collection(firestore, 'issues'));
  const issuesSnapshot = await getDocs(issuesQuery);
  
  let totalIssues = 0;
  let openIssues = 0;
  let completedIssues = 0;
  const issueTrendData: { date: string; count: number }[] = [];
  const statusDistribution: { [key: string]: number } = {};

  issuesSnapshot.forEach((doc) => {
    const issue = doc.data() as Issue;
    if (!issue.isDeleted && !issue.deletedAt) {
      totalIssues++;
      if (issue.status === 'Completed') {
        completedIssues++;
      } else {
        openIssues++;
      }

      // Populate issueTrendData and statusDistribution
      const date = new Date(issue.createdOn).toISOString().split('T')[0];
      const existingDate = issueTrendData.find(item => item.date === date);
      if (existingDate) {
        existingDate.count++;
      } else {
        issueTrendData.push({ date, count: 1 });
      }

      statusDistribution[issue.status] = (statusDistribution[issue.status] || 0) + 1;
    }
  });

  const projects = await Promise.all(projectsSnapshot.docs.map(async (doc) => {
    const projectData = doc.data() as Project;
    if (projectData.isArchived) {
      return null;
    }
    const projectIssuesQuery = query(
      collection(firestore, 'issues'),
      where('projectId', '==', doc.id)
    );
    const projectIssuesSnapshot = await getDocs(projectIssuesQuery);
    const issuesList = projectIssuesSnapshot.docs
      .map(issueDoc => {
        const issueData = issueDoc.data() as Issue;
        return {
          ...issueData,
          id: issueDoc.id
        };
      })
      .filter(issue => !issue.isDeleted && !issue.deletedAt);

    const openIssues = issuesList.filter(issue => issue.status !== 'Completed').length;
    const completedIssues = issuesList.filter(issue => issue.status === 'Completed').length;
    const lastUpdated = issuesList.length > 0 
      ? new Date(Math.max(...issuesList.map(issue => new Date(issue.lastUpdated).getTime()))).toISOString()
      : projectData.createdOn;

    console.log(`Project ${projectData.name}: Total Issues - ${issuesList.length}, Open - ${openIssues}, Completed - ${completedIssues}`);

    return {
      ...projectData,
      id: doc.id,
      openIssues,
      completedIssues,
      totalIssues: issuesList.length,
      lastUpdated,
      issuesList
    };
  }));

  const activeProjects = projects.filter(project => project !== null) as (Project & {
    openIssues: number;
    completedIssues: number;
    totalIssues: number;
    lastUpdated: string;
    issuesList: Issue[];
  })[];

  // Fetch users
  const usersQuery = query(collection(firestore, 'users'), where('status', '==', 'Active'));
  const usersSnapshot = await getDocs(usersQuery);
  const users = usersSnapshot.docs.map(doc => {
    const data = doc.data() as Omit<PartialUser, 'id'>;
    return {
      id: doc.id,
      userId: data.userId,
      firstName: data.firstName,
      lastName: data.lastName,
      avatarUrl: data.avatarUrl
    } as PartialUser;
  });

  // Update user issues queries
  const userIssuesData = await Promise.all(users.map(async (user) => {
    const userIssuesQuery = query(
      collection(firestore, 'issues'),
      where('assignedTo', 'array-contains', user.userId)
    );
    const userIssuesSnapshot = await getDocs(userIssuesQuery);
    
    const openIssues = userIssuesSnapshot.docs.filter(doc => {
      const issue = doc.data() as Issue;
      return !issue.isDeleted && !issue.deletedAt && issue.status !== 'Completed';
    }).length;

    const completedIssues = userIssuesSnapshot.docs.filter(doc => {
      const issue = doc.data() as Issue;
      return !issue.isDeleted && !issue.deletedAt && issue.status === 'Completed';
    }).length;

    const createdIssuesQuery = query(
      collection(firestore, 'issues'),
      where('createdBy', '==', user.userId)
    );
    const createdIssuesSnapshot = await getDocs(createdIssuesQuery);
    const createdIssues = createdIssuesSnapshot.docs.filter(doc => {
      const issue = doc.data() as Issue;
      return !issue.isDeleted && !issue.deletedAt;
    }).length;

    const openIssuesList = userIssuesSnapshot.docs
      .filter(doc => {
        const issue = doc.data() as Issue;
        return !issue.isDeleted && !issue.deletedAt && issue.status !== 'Completed';
      })
      .map(doc => {
        const issue = doc.data() as Issue;
        return {
          id: doc.id,
          title: issue.title,
          projectId: issue.projectId,
          createdOn: issue.createdOn
        };
      });

    const completedIssuesList = userIssuesSnapshot.docs
      .filter(doc => {
        const issue = doc.data() as Issue;
        return !issue.isDeleted && !issue.deletedAt && issue.status === 'Completed';
      })
      .map(doc => {
        const issue = doc.data() as Issue;
        return {
          id: doc.id,
          title: issue.title,
          projectId: issue.projectId,
          createdOn: issue.createdOn
        };
      });

    const createdIssuesList = createdIssuesSnapshot.docs
      .filter(doc => {
        const issue = doc.data() as Issue;
        return !issue.isDeleted && !issue.deletedAt;
      })
      .map(doc => {
        const issue = doc.data() as Issue;
        return {
          id: doc.id,
          title: issue.title,
          projectId: issue.projectId,
          createdOn: issue.createdOn
        };
      });

    const lastUpdatedIssue = userIssuesSnapshot.docs
      .filter(doc => {
        const issue = doc.data() as Issue;
        return !issue.isDeleted && !issue.deletedAt;
      })
      .sort((a, b) => {
        const issueA = a.data() as Issue;
        const issueB = b.data() as Issue;
        return new Date(issueB.lastUpdated).getTime() - new Date(issueA.lastUpdated).getTime();
      })[0];

    return {
      avatar: user.avatarUrl || '',
      firstName: user.firstName,
      lastInitial: user.lastName.charAt(0),
      openIssues,
      completedIssues,
      createdIssues,
      lastUpdated: lastUpdatedIssue ? (lastUpdatedIssue.data() as Issue).lastUpdated : '',
      openIssuesList,
      completedIssuesList,
      createdIssuesList
    };
  }));

  return {
    totalProjects,
    totalIssues,
    openIssues,
    completedIssues,
    issueTrendData,
    completionRateData: [
      { name: 'Completed', value: completedIssues },
      { name: 'Open', value: openIssues }
    ],
    statusDistributionData: Object.entries(statusDistribution).map(([name, value]) => ({ name, value })),
    projects: activeProjects,
    userEngagementData: [], // Implement this based on your user engagement tracking logic
    userIssuesData
  };
};