import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

interface IssueTrendChartProps {
  data: { date: string; count: number }[];
}

const IssueTrendChart: React.FC<IssueTrendChartProps> = ({ data }) => {
  // Sort the data by date in ascending order
  const sortedData = [...data].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={sortedData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey="date" 
          tickFormatter={(tick) => new Date(tick).toLocaleDateString()}
          angle={-45}
          textAnchor="end"
          height={70}
        />
        <YAxis />
        <Tooltip labelFormatter={(label) => new Date(label).toLocaleDateString()} />
        <Legend />
        <Line type="monotone" dataKey="count" stroke="#8884d8" activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default IssueTrendChart;