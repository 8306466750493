import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListItemText,
  OutlinedInput,
  SelectChangeEvent,
  InputLabel,
  Popover,
  IconButton,
  Autocomplete,
  Chip,
} from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CloseIcon from '@mui/icons-material/Close';
import { format } from 'date-fns';
import { User, ColumnType } from '../types';

interface FilterLightboxProps {
  open: boolean;
  onClose: () => void;
  filters: { [key: string]: any };
  visibleColumns: ColumnType[];
  onFilterChange: (key: string, value: any) => void;
  onClearAllFilters: () => void;
  allUsers: User[];
}

const CustomDateRangePicker: React.FC<{
  value: [Date | null, Date | null];
  onChange: (value: [Date | null, Date | null]) => void;
  label: string;
}> = ({ value, onChange, label }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [tempDates, setTempDates] = useState<[Date | null, Date | null]>(value);
  const [selectingFrom, setSelectingFrom] = useState(true);

  useEffect(() => {
    setTempDates(value);
  }, [value]);

  const handleClick = (event: React.MouseEvent<HTMLElement>, isFrom: boolean) => {
    setAnchorEl(event.currentTarget);
    setSelectingFrom(isFrom);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDateSelect = (date: Date | null) => {
    const newDates: [Date | null, Date | null] = selectingFrom
      ? [date, tempDates[1]]
      : [tempDates[0], date];
    setTempDates(newDates);
    onChange(newDates);
    handleClose();
  };

  const handleClearDate = (isFrom: boolean) => {
    const newDates: [Date | null, Date | null] = isFrom
      ? [null, tempDates[1]]
      : [tempDates[0], null];
    setTempDates(newDates);
    onChange(newDates);
  };

  const formatDate = (date: Date | null) => {
    return date ? format(date, 'MMM d, yyyy') : '';
  };

  const open = Boolean(anchorEl);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <TextField
          label={`${label} From`}
          value={formatDate(tempDates[0])}
          onClick={(e) => handleClick(e, true)}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <>
                <IconButton onClick={(e) => handleClick(e, true)}>
                  <CalendarTodayIcon />
                </IconButton>
                {tempDates[0] && (
                  <IconButton onClick={() => handleClearDate(true)} size="small">
                    <CloseIcon fontSize="small" />
                  </IconButton>
                )}
              </>
            ),
          }}
          variant="outlined"
          size="small"
        />
        <TextField
          label={`${label} To`}
          value={formatDate(tempDates[1])}
          onClick={(e) => handleClick(e, false)}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <>
                <IconButton onClick={(e) => handleClick(e, false)}>
                  <CalendarTodayIcon />
                </IconButton>
                {tempDates[1] && (
                  <IconButton onClick={() => handleClearDate(false)} size="small">
                    <CloseIcon fontSize="small" />
                  </IconButton>
                )}
              </>
            ),
          }}
          variant="outlined"
          size="small"
        />
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box p={2}>
          <DateCalendar
            value={selectingFrom ? tempDates[0] : tempDates[1]}
            onChange={(newDate) => handleDateSelect(newDate)}
            minDate={selectingFrom ? undefined : tempDates[0] || undefined}
            maxDate={!selectingFrom ? undefined : tempDates[1] || undefined}
          />
        </Box>
      </Popover>
    </LocalizationProvider>
  );
};

const FilterLightbox: React.FC<FilterLightboxProps> = ({
  open,
  onClose,
  filters,
  visibleColumns,
  onFilterChange,
  onClearAllFilters,
  allUsers,
}) => {
  const getFilterOptions = (column: ColumnType) => {
    switch (column) {
      case 'type':
        return ['Issue', 'Enhancement'];
      case 'status':
        return ['Open', 'In Process', 'Needs Info', 'Not Correct', 'Completed'];
      case 'level':
        return ['Blocking', 'Not Blocking', 'Design', 'Functionality', 'Backend'];
      case 'assignedTo':
      case 'createdBy':
        return allUsers.map(user => ({ id: user.userId, name: `${user.firstName} ${user.lastName}` }));
      case 'items':
        return ['Has attachments', 'Has comments'];
      default:
        return [];
    }
  };

  const renderFilterContent = (column: ColumnType) => {
    const options = getFilterOptions(column);

    if (['createdOn', 'lastUpdated'].includes(column)) {
      const dateFilter = filters[column] || { from: null, to: null };
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <CustomDateRangePicker
            value={[
              dateFilter.from ? new Date(dateFilter.from) : null,
              dateFilter.to ? new Date(dateFilter.to) : null
            ]}
            onChange={(newValue) => {
              onFilterChange(column, {
                from: newValue[0] ? newValue[0].toISOString() : null,
                to: newValue[1] ? newValue[1].toISOString() : null
              });
            }}
            label={getColumnHeader(column)}
          />
          <IconButton onClick={() => onFilterChange(column, { from: null, to: null })} size="small">
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      );
    }

    if (['id', 'title', 'description'].includes(column)) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            fullWidth
            label={`Filter by ${getColumnHeader(column)}`}
            value={filters[column] || ''}
            onChange={(e) => onFilterChange(column, e.target.value)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
          <IconButton onClick={() => onFilterChange(column, '')} size="small">
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      );
    }

    if (column === 'tags') {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Autocomplete
            multiple
            id="tags-filter"
            options={[]} // You can provide all available tags here if needed
            freeSolo
            renderTags={(value: string[], getTagProps) =>
              value.map((option: string, index: number) => (
                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Filter by Tags"
                placeholder="Select tags"
              />
            )}
            value={filters.tags || []}
            onChange={(_, newValue) => onFilterChange('tags', newValue)}
          />
          <IconButton onClick={() => onFilterChange('tags', [])} size="small">
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      );
    }

    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id={`${column}-label`} shrink>{`Filter by ${getColumnHeader(column)}`}</InputLabel>
          <Select
            labelId={`${column}-label`}
            multiple
            value={filters[column] || []}
            onChange={(event: SelectChangeEvent<string[]>) => {
              onFilterChange(column, event.target.value);
            }}
            input={<OutlinedInput notched label={`Filter by ${getColumnHeader(column)}`} />}
            renderValue={(selected) => {
              if (column === 'assignedTo' || column === 'createdBy') {
                return (selected as string[])
                  .map(id => allUsers.find(user => user.userId === id))
                  .filter(user => user !== undefined)
                  .map(user => `${user!.firstName} ${user!.lastName}`)
                  .join(', ');
              }
              return (selected as string[]).join(', ');
            }}
          >
            {options.map((option) => (
              <MenuItem key={typeof option === 'string' ? option : option.id} value={typeof option === 'string' ? option : option.id}>
                <Checkbox checked={(filters[column] || []).indexOf(typeof option === 'string' ? option : option.id) > -1} />
                <ListItemText primary={typeof option === 'string' ? option : option.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <IconButton onClick={() => onFilterChange(column, [])} size="small">
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
    );
  };

  const getColumnHeader = (column: ColumnType): string => {
    const headers: { [key: string]: string } = {
      id: 'ID',
      title: 'Title',
      description: 'Description',
      items: 'Items',
      type: 'Type',
      status: 'Status',
      level: 'Level',
      assignedTo: 'Assigned To',
      createdBy: 'Created By',
      createdOn: 'Created On',
      lastUpdated: 'Last Updated',
      tags: 'Tags',
    };
    return headers[column] || column;
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Filters</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2} mt={2}>
          {visibleColumns.map((column) => (
            <Box key={column}>
              {renderFilterContent(column)}
            </Box>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClearAllFilters} color="secondary">
          Clear All Filters
        </Button>
        <Button onClick={onClose} color="primary">
          Apply Filters
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilterLightbox;