import { firestore } from './firebase';
import { collection, addDoc, updateDoc, doc, getDoc, getDocs, query, orderBy, deleteDoc, where, onSnapshot } from 'firebase/firestore';
import { Issue, IssueUpdate, IssueFormData, Conversation, Task, Project } from './types';
import { fileService } from './fileService';
import { db } from './firebaseConfig';

const COLLECTION_NAME = 'issues';
const DEFAULT_PROJECT_ID = 'default-project-id';

export const issueService = {
  async getIssues(): Promise<Issue[]> {
    try {
      const issuesCol = collection(firestore, COLLECTION_NAME);
      const issueSnapshot = await getDocs(query(issuesCol, orderBy('createdOn', 'desc')));
      return issueSnapshot.docs.map((doc: any) => ({ id: doc.id, ...doc.data() } as Issue));
    } catch (error) {
      console.error("Error fetching issues:", error);
      throw error;
    }
  },

  async addIssue(issue: IssueFormData): Promise<Issue> {
    try {
      const now = new Date().toISOString();
      const tempId = `temp-${Date.now()}`;
      
      const attachmentPromises = issue.attachments.map((attachment: string | File) => {
        if (attachment instanceof File) {
          const safeFileName = attachment.name.replace(/\s+/g, '_');
          return fileService.uploadFile(new File([attachment], safeFileName, { type: attachment.type }), tempId);
        }
        return attachment;
      });
      const attachmentUrls = await Promise.all(attachmentPromises);

      const issueToSave = {
        ...issue,
        attachments: attachmentUrls,
        createdOn: now,
        lastUpdated: now,
        conversations: [],
        assignedTo: issue.assignedTo || [],
        projectId: issue.projectId || DEFAULT_PROJECT_ID,
        tasks: issue.tasks.map((task: any) => ({
          ...task,
          createdOn: task.createdOn || now,
          completedOn: task.completed ? (task.completedOn || now) : null,
          editedAt: task.editedAt || null
        })),
        isDeleted: false,
        tags: issue.tags || [],
        lastEditedBy: issue.lastEditedBy || '',
        lastEditedByName: issue.lastEditedByName || '',
        createdByName: issue.createdByName || '',
      };
      
      const docRef = await addDoc(collection(firestore, COLLECTION_NAME), issueToSave);
      const newIssueSnapshot = await getDoc(docRef);
      
      if (newIssueSnapshot.exists()) {
        const newIssue = { id: newIssueSnapshot.id, ...newIssueSnapshot.data() } as Issue;
        
        // If the temporary ID is different from the actual ID, update the file paths
        if (tempId !== newIssue.id) {
          const updatedAttachments = await Promise.all(newIssue.attachments.map(async (attachment) => {
            if (typeof attachment === 'string' && attachment.includes(tempId)) {
              const newUrl = attachment.replace(tempId, newIssue.id);
              await fileService.moveFile(attachment, newUrl);
              return newUrl;
            }
            return attachment;
          }));
          
          newIssue.attachments = updatedAttachments;
          await updateDoc(docRef, { attachments: updatedAttachments });
        }
        
        return newIssue;
      } else {
        throw new Error("Failed to retrieve the newly created issue");
      }
    } catch (error) {
      console.error("Error adding issue:", error);
      throw error;
    }
  },

  async updateIssue(issue: Issue): Promise<void> {
    try {
      const issueRef = doc(firestore, COLLECTION_NAME, issue.id);
      const originalIssue = await this.getIssue(issue.id);
      
      const updatedIssue = {
        ...issue,
        lastUpdated: new Date().toISOString(),
      };

      await updateDoc(issueRef, updatedIssue);

      const fieldChanges: { [key: string]: { label: string; oldValue: any; newValue: any } } = {};

      // Compare only the fields that can be changed together
      const fieldsToTrack: (keyof Issue)[] = ['title', 'description', 'type', 'status', 'level', 'assignedTo', 'tags'];
      
      fieldsToTrack.forEach((field) => {
        if (JSON.stringify(originalIssue[field]) !== JSON.stringify(updatedIssue[field])) {
          fieldChanges[field] = {
            label: `${field.charAt(0).toUpperCase() + field.slice(1)} Changed`,
            oldValue: originalIssue[field],
            newValue: updatedIssue[field]
          };
        }
      });

      if (Object.keys(fieldChanges).length > 0) {
        const updateData: IssueUpdate = {
          id: Date.now().toString(),
          userId: updatedIssue.lastEditedBy || '',
          userName: updatedIssue.lastEditedByName || '',
          timestamp: new Date().toISOString(),
          changes: fieldChanges,
          projectId: issue.projectId,
          issueTitle: issue.title,
          issueId: issue.id
        };

        const updateRef = collection(issueRef, 'updates');
        await addDoc(updateRef, updateData);
      }

    } catch (error) {
      console.error("Error updating issue:", error);
      throw error;
    }
  },

  async deleteIssue(id: string): Promise<void> {
    try {
      await deleteDoc(doc(firestore, COLLECTION_NAME, id));
    } catch (error) {
      console.error("Error deleting issue:", error);
      throw error;
    }
  },

  async addConversation(issueId: string, newConversation: Omit<Conversation, 'id'>): Promise<Issue> {
    try {
      const issueRef = doc(firestore, COLLECTION_NAME, issueId);
      const conversation = { id: Date.now().toString(), ...newConversation };
      const issue = await this.getIssue(issueId);
      const updatedConversations = [...(issue.conversations || []), conversation];
      await updateDoc(issueRef, {
        conversations: updatedConversations,
        lastUpdated: new Date().toISOString()
      });

      // Create an update document for the new conversation
      const updateData: IssueUpdate = {
        id: Date.now().toString(),
        userId: issue.lastEditedBy || '',
        userName: issue.lastEditedByName || '',
        timestamp: new Date().toISOString(),
        changes: {
          'conversation': {
            label: 'New Comment',
            oldValue: null,
            newValue: conversation.message
          }
        },
        projectId: issue.projectId,
        issueTitle: issue.title,
        issueId: issue.id
      };
      const updateRef = collection(issueRef, 'updates');
      await addDoc(updateRef, updateData);

      return await this.getIssue(issueId);
    } catch (error) {
      console.error("Error adding conversation:", error);
      throw error;
    }
  },

  async editConversation(issueId: string, conversationId: string, updatedMessage: string): Promise<Issue> {
    try {
      const issue = await this.getIssue(issueId);
      const conversationIndex = issue.conversations.findIndex(conv => conv.id === conversationId);
      if (conversationIndex === -1) throw new Error("Conversation not found");

      const oldConversation = issue.conversations[conversationIndex];
      const newConversation = { ...oldConversation, message: updatedMessage, editedAt: new Date().toISOString() };
      issue.conversations[conversationIndex] = newConversation;

      const issueRef = doc(firestore, COLLECTION_NAME, issueId);
      await updateDoc(issueRef, {
        conversations: issue.conversations,
        lastUpdated: new Date().toISOString()
      });

      // Create an update document for the edited conversation
      const updateData: IssueUpdate = {
        id: Date.now().toString(),
        userId: issue.lastEditedBy || '',
        userName: issue.lastEditedByName || '',
        timestamp: new Date().toISOString(),
        changes: {
          'conversation': {
            label: 'Edited Comment',
            oldValue: oldConversation.message,
            newValue: newConversation.message
          }
        },
        projectId: issue.projectId,
        issueTitle: issue.title,
        issueId: issue.id
      };
      const updateRef = collection(issueRef, 'updates');
      await addDoc(updateRef, updateData);

      return await this.getIssue(issueId);
    } catch (error) {
      console.error("Error editing conversation:", error);
      throw error;
    }
  },

  async addAttachment(issueId: string, newAttachment: File): Promise<Issue> {
    try {
      const attachmentUrl = await fileService.uploadFile(newAttachment, issueId);
      const issueRef = doc(firestore, COLLECTION_NAME, issueId);
      const issue = await this.getIssue(issueId);
      const updatedAttachments = [...(issue.attachments || []), attachmentUrl];
      await updateDoc(issueRef, {
        attachments: updatedAttachments,
        lastUpdated: new Date().toISOString()
      });

      // Create an update document for the new attachment
      const updateData: IssueUpdate = {
        id: Date.now().toString(),
        userId: issue.lastEditedBy || '',
        userName: issue.lastEditedByName || '',
        timestamp: new Date().toISOString(),
        changes: {
          'attachment': {
            label: 'New Attachment',
            oldValue: null,
            newValue: attachmentUrl
          }
        },
        projectId: issue.projectId,
        issueTitle: issue.title,
        issueId: issue.id
      };
      const updateRef = collection(issueRef, 'updates');
      await addDoc(updateRef, updateData);

      return await this.getIssue(issueId);
    } catch (error) {
      console.error("Error adding attachment:", error);
      throw error;
    }
  },

  async deleteAttachment(issueId: string, attachmentUrl: string): Promise<Issue> {
    try {
      const issue = await this.getIssue(issueId);
      const updatedAttachments = issue.attachments.filter(url => url !== attachmentUrl);

      const issueRef = doc(firestore, COLLECTION_NAME, issueId);
      await updateDoc(issueRef, {
        attachments: updatedAttachments,
        lastUpdated: new Date().toISOString()
      });

      // Create an update document for the deleted attachment
      const updateData: IssueUpdate = {
        id: Date.now().toString(),
        userId: issue.lastEditedBy || '',
        userName: issue.lastEditedByName || '',
        timestamp: new Date().toISOString(),
        changes: {
          'attachment': {
            label: 'Deleted Attachment',
            oldValue: attachmentUrl,
            newValue: null
          }
        },
        projectId: issue.projectId,
        issueTitle: issue.title,
        issueId: issue.id
      };
      const updateRef = collection(issueRef, 'updates');
      await addDoc(updateRef, updateData);

      // Delete the file from storage
      await fileService.deleteFile(attachmentUrl);

      // Return the updated issue
      return await this.getIssue(issueId);
    } catch (error) {
      console.error("Error deleting attachment:", error);
      throw error;
    }
  },

  async getIssue(id: string): Promise<Issue> {
    try {
      const issueDoc = await getDoc(doc(firestore, COLLECTION_NAME, id));
      if (!issueDoc.exists()) {
        throw new Error('Issue not found');
      }
      return { id: issueDoc.id, ...issueDoc.data() } as Issue;
    } catch (error) {
      console.error("Error fetching issue:", error);
      throw error;
    }
  },

  async getIssuesByProject(projectId: string): Promise<Issue[]> {
    try {
      const allIssues = await this.getIssues();
      
      if (projectId === DEFAULT_PROJECT_ID) {
        return allIssues.filter(issue => !issue.projectId || issue.projectId === DEFAULT_PROJECT_ID);
      } else {
        return allIssues.filter(issue => issue.projectId === projectId);
      }
    } catch (error) {
      console.error("Error fetching issues by project:", error);
      throw new Error("Failed to fetch issues. Please try again.");
    }
  },

  async getIssueUpdates(issueId: string): Promise<IssueUpdate[]> {
    try {
      const issueRef = doc(firestore, COLLECTION_NAME, issueId);
      const updatesRef = collection(issueRef, 'updates');
      const updatesSnapshot = await getDocs(query(updatesRef, orderBy('timestamp', 'desc')));
      const issue = await this.getIssue(issueId);
      return updatesSnapshot.docs.map((doc: any) => {
        const data = doc.data();
        if (
          typeof data.userId === 'string' &&
          typeof data.userName === 'string' &&
          typeof data.timestamp === 'string' &&
          typeof data.changes === 'object'
        ) {
          return { 
            id: doc.id, 
            userId: data.userId,
            userName: data.userName,
            timestamp: data.timestamp,
            changes: data.changes,
            attributes: data.attributes || [],
            projectId: issue.projectId,
            issueTitle: issue.title,
            issueId: issue.id
          } as IssueUpdate;
        }
        throw new Error(`Invalid IssueUpdate data for document ${doc.id}`);
      });
    } catch (error) {
      console.error("Error fetching issue updates:", error);
      throw error;
    }
  },

  async getAllIssueUpdates(): Promise<IssueUpdate[]> {
    try {
      console.log('Fetching all issue updates...');
      const issuesRef = collection(firestore, COLLECTION_NAME);
      const issuesQuery = query(
        issuesRef,
        where('isDeleted', '==', false),
        orderBy('lastUpdated', 'desc')
      );
      const issuesSnapshot = await getDocs(issuesQuery);
      
      console.log(`Found ${issuesSnapshot.docs.length} issues`);
      
      const allUpdates: IssueUpdate[] = [];

      for (const issueDoc of issuesSnapshot.docs) {
        const issueData = issueDoc.data() as Issue;
        const updatesRef = collection(issueDoc.ref, 'updates');
        const updatesQuery = query(updatesRef, orderBy('timestamp', 'desc'));
        const updatesSnapshot = await getDocs(updatesQuery);
        
        console.log(`Found ${updatesSnapshot.docs.length} updates for issue ${issueDoc.id}`);
        
        const issueUpdates = updatesSnapshot.docs.map(doc => {
          const data = doc.data();
          console.log('Update data:', data);
          return {
            id: doc.id,
            userId: data.userId,
            userName: data.userName,
            timestamp: data.timestamp,
            changes: data.changes,
            projectId: issueData.projectId,
            issueTitle: issueData.title,
            issueId: issueDoc.id
          } as IssueUpdate;
        });

        allUpdates.push(...issueUpdates);
      }

      console.log(`Total updates fetched: ${allUpdates.length}`);

      return allUpdates.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
    } catch (error) {
      console.error('Error fetching all issue updates:', error);
      throw error;
    }
  },

  async addTask(issueId: string, newTask: Omit<Task, 'id'>): Promise<Issue> {
    try {
      const task = { 
        id: Date.now().toString(), 
        ...newTask, 
        createdOn: newTask.createdOn || new Date().toISOString(),
        completedOn: newTask.completedOn || null,
        editedAt: newTask.editedAt || null
      };
      const issue = await this.getIssue(issueId);
      const updatedTasks = [...(issue.tasks || []), task];
      await updateDoc(doc(firestore, COLLECTION_NAME, issueId), {
        tasks: updatedTasks,
        lastUpdated: new Date().toISOString()
      });

      // Create an update document for the new task
      const updateData: IssueUpdate = {
        id: Date.now().toString(),
        userId: issue.lastEditedBy || '',
        userName: issue.lastEditedByName || '',
        timestamp: new Date().toISOString(),
        changes: {
          'task': {
            label: 'New Task',
            oldValue: null,
            newValue: task.text
          }
        },
        projectId: issue.projectId,
        issueTitle: issue.title,
        issueId: issue.id
      };
      const updateRef = collection(doc(firestore, COLLECTION_NAME, issueId), 'updates');
      await addDoc(updateRef, updateData);

      return await this.getIssue(issueId);
    } catch (error) {
      console.error("Error adding task:", error);
      throw error;
    }
  },

  async editTask(issueId: string, taskId: string, updatedTask: Partial<Task>): Promise<Issue> {
    try {
      const issue = await this.getIssue(issueId);
      const taskIndex = issue.tasks.findIndex(task => task.id === taskId);
      if (taskIndex === -1) throw new Error("Task not found");

      const oldTask = issue.tasks[taskIndex];
      const newTask = { ...oldTask, ...updatedTask, editedAt: new Date().toISOString() };
      issue.tasks[taskIndex] = newTask;

      const issueRef = doc(firestore, COLLECTION_NAME, issueId);
      await updateDoc(issueRef, {
        tasks: issue.tasks,
        lastUpdated: new Date().toISOString()
      });

      // Create an update document for the edited task
      const updateData: IssueUpdate = {
        id: Date.now().toString(),
        userId: issue.lastEditedBy || '',
        userName: issue.lastEditedByName || '',
        timestamp: new Date().toISOString(),
        changes: {
          'task': {
            label: 'Edited Task',
            oldValue: oldTask.text,
            newValue: newTask.text
          }
        },
        projectId: issue.projectId,
        issueTitle: issue.title,
        issueId: issue.id
      };
      const updateRef = collection(issueRef, 'updates');
      await addDoc(updateRef, updateData);

      return await this.getIssue(issueId);
    } catch (error) {
      console.error("Error editing task:", error);
      throw error;
    }
  },

  async deleteTask(issueId: string, taskId: string): Promise<Issue> {
    try {
      const issue = await this.getIssue(issueId);
      const taskIndex = issue.tasks.findIndex(task => task.id === taskId);
      if (taskIndex === -1) throw new Error("Task not found");

      const deletedTask = issue.tasks[taskIndex];
      issue.tasks.splice(taskIndex, 1);

      const issueRef = doc(firestore, COLLECTION_NAME, issueId);
      await updateDoc(issueRef, {
        tasks: issue.tasks,
        lastUpdated: new Date().toISOString()
      });

      // Create an update document for the deleted task
      const updateData: IssueUpdate = {
        id: Date.now().toString(),
        userId: issue.lastEditedBy || '',
        userName: issue.lastEditedByName || '',
        timestamp: new Date().toISOString(),
        changes: {
          'task': {
            label: 'Deleted Task',
            oldValue: deletedTask.text,
            newValue: null
          }
        },
        projectId: issue.projectId,
        issueTitle: issue.title,
        issueId: issue.id
      };
      const updateRef = collection(issueRef, 'updates');
      await addDoc(updateRef, updateData);

      return await this.getIssue(issueId);
    } catch (error) {
      console.error("Error deleting task:", error);
      throw error;
    }
  }
};

export const getGlobalUpdates = async (): Promise<IssueUpdate[]> => {
  const cachedUpdatesDoc = await getDoc(doc(db, "cachedUpdates", "allUpdates"));
  
  if (cachedUpdatesDoc.exists()) {
    const data = cachedUpdatesDoc.data();
    return data.updates || [];
  }

  return [];
};

export const subscribeToGlobalUpdates = (
  projects: Project[],
  callback: (updates: IssueUpdate[]) => void
) => {
  return onSnapshot(doc(db, "cachedUpdates", "allUpdates"), (doc) => {
    if (doc.exists()) {
      const data = doc.data();
      const updates = (data.updates || []).filter((update: IssueUpdate) => 
        projects.some(project => project.id === update.projectId)
      );
      callback(updates);
    } else {
      callback([]);
    }
  });
};

export const getIssueUpdates = async (issueId: string): Promise<IssueUpdate[]> => {
  const cachedUpdatesDoc = await getDoc(doc(db, "cachedUpdates", "allUpdates"));
  
  if (cachedUpdatesDoc.exists()) {
    const data = cachedUpdatesDoc.data();
    return (data.updates || []).filter((update: IssueUpdate) => update.issueId === issueId);
  }

  return [];
};

export const subscribeToIssueUpdates = (
  issueId: string,
  callback: (updates: IssueUpdate[]) => void
) => {
  return onSnapshot(doc(db, "cachedUpdates", "allUpdates"), (doc) => {
    if (doc.exists()) {
      const data = doc.data();
      const updates = (data.updates || []).filter((update: IssueUpdate) => update.issueId === issueId);
      callback(updates);
    } else {
      callback([]);
    }
  });
};

export default issueService;