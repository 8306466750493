import React, { useState, useMemo, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult, DroppableProvided, DraggableProvided } from 'react-beautiful-dnd';
import { Box, Typography, Card, CardContent, Chip, Avatar, IconButton, AvatarGroup, Tooltip, LinearProgress } from '@mui/material';
import { Edit as EditIcon, AttachFile as AttachmentIcon, Comment as CommentIcon } from '@mui/icons-material';
import { Issue, User, KanbanColumnType } from '../types';
import '../styles/KanbanBoard.css';

interface KanbanBoardProps {
  issues: Issue[];
  onUpdateIssue: (updatedIssue: Issue) => Promise<void>;
  onEditIssue: (issue: Issue) => void;
  users: User[];
  visibleColumns: KanbanColumnType[];
  onIssueSelect: (issue: Issue) => void;
}

const KanbanBoard: React.FC<KanbanBoardProps> = ({ 
  issues, 
  onUpdateIssue, 
  onEditIssue, 
  users, 
  visibleColumns,
  onIssueSelect,
}) => {
  const [draggingIssue, setDraggingIssue] = useState<Issue | null>(null);

  const columns = useMemo(() => {
    return visibleColumns.reduce((acc, status) => {
      acc[status] = issues.filter(issue => issue.status === status);
      return acc;
    }, {} as Record<KanbanColumnType, Issue[]>);
  }, [issues, visibleColumns]);

  const onDragEnd = useCallback(async (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) return;

    if (source.droppableId === destination.droppableId && source.index === destination.index) return;

    const sourceStatus = source.droppableId as KanbanColumnType;
    const destStatus = destination.droppableId as KanbanColumnType;

    const sourceColumn = [...columns[sourceStatus]];
    const destColumn = [...columns[destStatus]];
    const [removed] = sourceColumn.splice(source.index, 1);
    destColumn.splice(destination.index, 0, { ...removed, status: destStatus });

    const updatedIssue = { ...removed, status: destStatus };
    await onUpdateIssue(updatedIssue);
    setDraggingIssue(null);
  }, [columns, onUpdateIssue]);

  const getChipColor = (label: string) => {
    switch (label) {
      case 'Blocking': return '#FFC0CB';
      case 'Design': return '#A9A9A9';
      case 'In Process': return '#E6E6FA';
      case 'Completed': return '#98FB98';
      case 'Open': return '#ADD8E6';
      case 'Not Blocking': return '#FAFAD2';
      case 'Functionality': return '#87CEFA';
      case 'Backend': return '#DDA0DD';
      case 'Issue': return '#FFB347';
      case 'Enhancement': return '#87CEEB';
      default: return '#E0E0E0';
    }
  };

  const getChipTextColor = (label: string) => {
    switch (label) {
      case 'Blocking': return '#FFFFFF';
      case 'Design': return '#000000';
      default: return '#000000';
    }
  };

  const formatDateTime = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  };

  const renderKanbanCard = useCallback((issue: Issue, provided: DraggableProvided) => {
    const creator = users.find(u => u.userId === issue.createdBy);
    const assignees = users.filter(u => issue.assignedTo?.includes(u.userId || ''));
    const completedTasks = issue.tasks?.filter(task => task.completed).length || 0;
    const totalTasks = issue.tasks?.length || 0;

    return (
      <Card
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        className="kanban-card"
        sx={{ position: 'relative', '&:hover .edit-icon': { display: 'block' } }}
        onClick={() => onIssueSelect(issue)}
      >
        <CardContent className="kanban-card-content" sx={{ p: 1.5, '&:last-child': { pb: 1.5 } }}>
          <Typography 
            className="kanban-card-title" 
            sx={{ 
              fontWeight: 'bold', 
              fontSize: '16px', 
              mb: 1, 
              lineHeight: 1.2,
              height: '2.4em',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {issue.title}
          </Typography>
          
          <Box display="flex" alignItems="center" mb={1}>
            <Avatar src={creator?.avatarUrl} alt={creator?.firstName} sx={{ width: 20, height: 20, mr: 1 }} />
            <Typography variant="caption" sx={{ mr: 1 }}>
              {creator ? `${creator.firstName} ${creator.lastName}` : 'Unknown'}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              Created: {formatDateTime(issue.createdOn)}
            </Typography>
          </Box>
          
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
            <Box display="flex" alignItems="center">
              <Typography variant="caption" sx={{ mr: 1 }}>Assigned to:</Typography>
              <AvatarGroup max={3} sx={{ '& .MuiAvatar-root': { width: 20, height: 20 } }}>
                {assignees.map((user) => (
                  <Tooltip key={user.userId} title={`${user.firstName} ${user.lastName}`}>
                    <Avatar src={user.avatarUrl} alt={user.firstName} />
                  </Tooltip>
                ))}
              </AvatarGroup>
            </Box>
            {totalTasks > 0 && (
              <Typography variant="caption" sx={{ color: 'green' }}>
                {completedTasks}/{totalTasks}
              </Typography>
            )}
          </Box>
          
          {totalTasks > 0 && (
            <Box sx={{ mt: 1, mb: 1 }}>
              <LinearProgress
                variant="determinate"
                value={(completedTasks / totalTasks) * 100}
                sx={{
                  height: 10,
                  borderRadius: 5,
                  backgroundColor: '#e0e0e0',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                  border: '1px solid #bdbdbd',
                  '& .MuiLinearProgress-bar': {
                    borderRadius: 5,
                    backgroundColor: '#4caf50',
                  },
                }}
              />
            </Box>
          )}
          
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <Chip 
                label={issue.type} 
                size="small" 
                sx={{ 
                  mr: 0.5, 
                  height: 20, 
                  fontSize: '0.7rem',
                  bgcolor: getChipColor(issue.type),
                  color: getChipTextColor(issue.type)
                }} 
              />
              <Chip 
                label={issue.level} 
                size="small" 
                sx={{ 
                  height: 20, 
                  fontSize: '0.7rem',
                  bgcolor: getChipColor(issue.level),
                  color: getChipTextColor(issue.level)
                }} 
              />
            </Box>
            <Box>
              {issue.attachments.length > 0 && (
                <IconButton size="small" sx={{ p: 0.5 }}>
                  <AttachmentIcon fontSize="small" />
                  <Typography variant="caption" sx={{ ml: 0.5 }}>{issue.attachments.length}</Typography>
                </IconButton>
              )}
              {issue.conversations.length > 0 && (
                <IconButton size="small" sx={{ p: 0.5 }}>
                  <CommentIcon fontSize="small" />
                  <Typography variant="caption" sx={{ ml: 0.5 }}>{issue.conversations.length}</Typography>
                </IconButton>
              )}
            </Box>
          </Box>
        </CardContent>
        <IconButton
          className="edit-icon"
          sx={{
            position: 'absolute',
            top: 5,
            right: 5,
            display: 'none',
            p: 0.5,
          }}
          onClick={(e) => {
            e.stopPropagation();
            onEditIssue(issue);
          }}
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </Card>
    );
  }, [users, onEditIssue, onIssueSelect]);

  return (
    <Box sx={{ width: '100%', overflowX: 'auto' }}>
      <DragDropContext 
        onDragEnd={onDragEnd} 
        onDragStart={(start: any) => setDraggingIssue(issues.find(issue => issue.id === start.draggableId) || null)}
      >
        <Box 
          className="kanban-board" 
          sx={{ 
            display: 'flex', 
            minWidth: `${visibleColumns.length * 300}px`,
            height: 'calc(100vh - 200px)',
          }}
        >
          {visibleColumns.map(status => (
            <Box 
              key={status} 
              className="kanban-column"
              sx={{ 
                flex: 1,
                flexBasis: 0,
                minWidth: '300px',
                padding: '0 8px',
              }}
            >
              <Box className="kanban-column-content" sx={{ height: '100%', overflow: 'hidden' }}>
                <Box className="kanban-column-header">
                  {status} ({columns[status]?.length || 0})
                </Box>
                <Droppable droppableId={status}>
                  {(provided: DroppableProvided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="kanban-column-cards"
                      sx={{ height: 'calc(100% - 48px)', overflowY: 'auto' }}
                    >
                      {columns[status]?.map((issue, index) => (
                        <Draggable key={issue.id} draggableId={issue.id} index={index}>
                          {(provided: DraggableProvided) => renderKanbanCard(issue, provided)}
                        </Draggable>
                      ))}
                      {draggingIssue && draggingIssue.status === status && (
                        <Box 
                          sx={{ 
                            border: '1px dashed #ccc', 
                            backgroundColor: '#f0f8ff', 
                            padding: 1, 
                            marginBottom: 1 
                          }}
                        >
                          {draggingIssue.title}
                        </Box>
                      )}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </Box>
            </Box>
          ))}
        </Box>
      </DragDropContext>
    </Box>
  );
};

export default React.memo(KanbanBoard);