import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { userService } from './userService';
import Swal from 'sweetalert2';
import './styles/LoginPage.css';
import { TextField, Button, Box, Typography } from '@mui/material';

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSignup, setIsSignup] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const user = await userService.login(email, password);
      if (user.status === 'Pending' || user.status === 'Inactive') {
        Swal.fire({
          icon: 'error',
          title: 'Login Failed',
          text: 'Your account is not active. Please contact an administrator.',
        });
      } else {
        localStorage.setItem('user', JSON.stringify(user));
        navigate('/issues');
      }
    } catch (error) {
      console.error('Login failed:', error);
      Swal.fire({
        icon: 'error',
        title: 'Login Failed',
        text: 'Invalid credentials. Please try again.',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSignup = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await userService.createUser({
        email,
        firstName,
        lastName,
        role: '',
        status: 'Pending',
        isAdmin: false,
        newUserNotificationDismissed: false,
        permissions: {
          canCreateIssues: false,
          canBeAssignedIssues: false,
          canEditProjectSettings: false,
          canEditIssues: false
        },
        createdOn: new Date().toISOString(),
      }, password);
      Swal.fire({
        icon: 'success',
        title: 'Sign Up Successful',
        text: 'Your account has been created and is pending approval. You will be notified when your account is activated.',
      });
      setIsSignup(false);
    } catch (error) {
      console.error('Signup failed:', error);
      Swal.fire({
        icon: 'error',
        title: 'Sign Up Failed',
        text: 'An error occurred during sign up. Please try again.',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <img src="/yepex-logo.png" alt="Yepex.ai" className="logo" />
        <Typography variant="h4" className="welcome-text">
          Welcome to Yepex.ai
        </Typography>
        <Typography variant="body1" className="subtitle-text">
          Where your software problems come to get squashed! Bugs, glitches, or feature requests, we've got your back.
        </Typography>
        {isSignup ? (
          <form onSubmit={handleSignup} className="login-form">
            
            <TextField
              fullWidth
              margin="normal"
              label="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
              className="input-field"
            />
            <TextField
              fullWidth
              margin="normal"
              label="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
              className="input-field"
            />
            <TextField
              fullWidth
              margin="normal"
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="input-field"
            />
            <TextField
              fullWidth
              margin="normal"
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="input-field"
            />
            <Button 
              type="submit" 
              variant="contained" 
              fullWidth 
              className="login-button"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Signing Up...' : 'SIGN UP'}
            </Button>
          </form>
        ) : (
          <form onSubmit={handleLogin} className="login-form">
            <TextField
              fullWidth
              margin="normal"
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="input-field"
            />
            <TextField
              fullWidth
              margin="normal"
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="input-field"
            />
            <Button 
              type="submit" 
              variant="contained" 
              fullWidth 
              className="login-button"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Logging In...' : 'LOGIN'}
            </Button>
          </form>
        )}
        <Box mt={2} display="flex" justifyContent="space-between" className="links-container">
          <Typography 
            variant="body2" 
            className="link-text"
            onClick={() => setIsSignup(!isSignup)}
            style={{ cursor: 'pointer' }}
          >
            {isSignup ? 'Back to Login' : 'Sign up'}
          </Typography>
          {!isSignup && (
            <Typography 
              variant="body2" 
              className="link-text"
              onClick={() => {/* Handle forgot password */}}
              style={{ cursor: 'pointer' }}
            >
              Forgot Password?
            </Typography>
          )}
        </Box>
      </div>
    </div>
  );
};

export default LoginPage;