import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import {
  Box,
  Button,
  IconButton,
  Drawer,
  Snackbar,
  Alert,
  InputAdornment,
  Dialog,
  DialogContent,
} from '@mui/material';
import {
  Add as AddIcon,
  ViewColumn as ViewColumnIcon,
  ViewList as ViewListIcon,
  ViewModule as ViewModuleIcon,
  ViewKanban as ViewKanbanIcon,
  FilterList as FilterListIcon,  
  Sort as SortIcon,
  Image as ImageIcon,
  TableChart as TableChartIcon,
  Search as SearchIcon,
  Close as CloseIcon,
  DescriptionOutlined as NotesIcon,
} from '@mui/icons-material';
import IssueList from './IssueList';
import IssueCard from './IssueCard';
import IssueForm from './IssueForm';
import IssueDetails from './IssueDetails';
import KanbanBoard from './KanbanBoard';
import ColumnSelector from './ColumnSelector';
import FilterLightbox from './FilterLightbox';
import SearchIssues from './SearchIssues';
import SortMenu from './SortMenu';
import ImageGalleryLightbox from './ImageGalleryLightbox';
import TableManager from './TableManager';
import { TableData } from '../types';
import NotesLightbox from './NotesLightbox';
import { Issue, User, SortConfig, IssueFormData, Project, ColumnType, KanbanColumnType } from '../types';
import { userService } from '../userService';
import useLocalStorage from '../hooks/useLocalStorage';
import { useAuth } from '../AppContent';
import ErrorBoundary from './ErrorBoundary';
import { useNavigate } from 'react-router-dom';


interface IssueListContainerProps {
  issues: Issue[];
  users: User[];
  onUpdateIssue: (updatedIssue: Issue) => Promise<void>;
  onAddIssue: (newIssue: Omit<Issue, 'id' | 'createdOn' | 'lastUpdated'>) => Promise<Issue>;
  currentUser: User;
  projectId: string | null;
  projects: Project[];
  onSelectIssue: (issue: Issue | null) => void;
  allTags: string[];
  selectedIssue: Issue | null;
}

const IssueListContainer: React.FC<IssueListContainerProps> = ({
  issues,
  users,
  onUpdateIssue,
  onAddIssue,
  currentUser,
  projectId,
  projects,
  onSelectIssue,
  allTags,
  selectedIssue,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useLocalStorage<{ [key: string]: any }>('issueFilters', {});
  const [sortConfig, setSortConfig] = useLocalStorage<SortConfig>('issueSortConfig', { key: 'createdOn', direction: 'desc' });
  const [visibleColumns, setVisibleColumns] = useLocalStorage<ColumnType[]>(
    'issueVisibleColumns',
    ['id', 'title', 'description', 'items', 'type', 'status', 'level', 'assignedTo', 'createdBy', 'createdOn', 'lastUpdated', 'tags']
  );
  const [columnOrder, setColumnOrder] = useLocalStorage<ColumnType[]>(
    'issueColumnOrder',
    ['id', 'title', 'description', 'items', 'type', 'status', 'level', 'assignedTo', 'createdBy', 'createdOn', 'lastUpdated', 'tags']
  );
  const [viewMode, setViewMode] = useLocalStorage<'list' | 'card' | 'kanban'>('issueViewMode', 'list');
  const [kanbanColumns, setKanbanColumns] = useLocalStorage<KanbanColumnType[]>(
    'kanbanColumns',
    ['Open', 'In Process', 'Needs Info', 'Not Correct', 'Completed']
  );
  const [visibleKanbanColumns, setVisibleKanbanColumns] = useLocalStorage<KanbanColumnType[]>(
    'visibleKanbanColumns',
    ['Open', 'In Process', 'Needs Info', 'Not Correct', 'Completed']
  );

  const [columnSelectorAnchorEl, setColumnSelectorAnchorEl] = useState<null | HTMLElement>(null);
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [isNewIssueDrawerOpen, setIsNewIssueDrawerOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [isFilterLightboxOpen, setIsFilterLightboxOpen] = useState(false);
  const [sortMenuAnchorEl, setSortMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [isImageGalleryOpen, setIsImageGalleryOpen] = useState(false);
  const { user } = useAuth();
  const [localIssues, setLocalIssues] = useState<Issue[]>(issues);
  const [isTableLightboxOpen, setIsTableLightboxOpen] = useState(false);
  const [isNotesLightboxOpen, setIsNotesLightboxOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLocalIssues(issues.filter(issue => !issue.isDeleted));
  }, [issues]);

  const fetchAllUsers = useCallback(async () => {
    try {
      const fetchedUsers = await userService.getAllUsers();
      setAllUsers(fetchedUsers);
    } catch (error) {
      console.error('Error fetching users:', error);
      showSnackbar('Failed to fetch users. Please try again later.', 'error');
    }
  }, []);

  useEffect(() => {
    fetchAllUsers();
  }, [fetchAllUsers]);

  const handleFilterChange = (key: string, value: any) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [key]: value
    }));
  };

  const handleClearAllFilters = () => {
    setFilters({});
  };

  const handleSortMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSortMenuAnchorEl(event.currentTarget);
  };

  const handleSortMenuClose = () => {
    setSortMenuAnchorEl(null);
  };

  const sortableColumns: (keyof Issue)[] = [
    'title', 'description', 'type', 'status', 'level', 'createdOn', 'lastUpdated'
  ];

  const handleSortChange = (key: string) => {
    setSortConfig(prevConfig => ({
      key: key as keyof Issue,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
    handleSortMenuClose();
  };

  const handleColumnSelectorClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setColumnSelectorAnchorEl(event.currentTarget);
  };

  const handleColumnSelectorClose = () => {
    setColumnSelectorAnchorEl(null);
  };

  const handleColumnToggle = useCallback((column: string) => {
    if (viewMode === 'kanban') {
      setVisibleKanbanColumns(prevColumns => {
        if (prevColumns.includes(column as KanbanColumnType)) {
          return prevColumns.filter(c => c !== column);
        } else {
          const newColumns = [...prevColumns, column as KanbanColumnType];
          return newColumns.sort((a, b) => kanbanColumns.indexOf(a) - kanbanColumns.indexOf(b));
        }
      });
    } else {
      setVisibleColumns(prevColumns => {
        if (prevColumns.includes(column as ColumnType)) {
          return prevColumns.filter(c => c !== column);
        } else {
          const newColumns = [...prevColumns, column as ColumnType];
          return newColumns.sort((a, b) => columnOrder.indexOf(a) - columnOrder.indexOf(b));
        }
      });
    }
  }, [viewMode, kanbanColumns, columnOrder, setVisibleColumns, setVisibleKanbanColumns]);

  const handleColumnReorder = useCallback((result: DropResult) => {
    if (!result.destination) return;

    const reorder = (list: any[], startIndex: number, endIndex: number) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    };

    if (viewMode === 'kanban') {
      const newKanbanColumns = reorder(
        kanbanColumns,
        result.source.index,
        result.destination.index
      );
      setKanbanColumns(newKanbanColumns);
      setVisibleKanbanColumns(prevColumns => 
        prevColumns.sort((a, b) => newKanbanColumns.indexOf(a) - newKanbanColumns.indexOf(b))
      );
    } else {
      const newColumnOrder = reorder(
        columnOrder,
        result.source.index,
        result.destination.index
      );
      setColumnOrder(newColumnOrder);
      setVisibleColumns(prevColumns => 
        prevColumns.sort((a, b) => newColumnOrder.indexOf(a) - newColumnOrder.indexOf(b))
      );
    }
  }, [viewMode, kanbanColumns, columnOrder, setKanbanColumns, setColumnOrder, setVisibleColumns, setVisibleKanbanColumns]);

  const isDateInRange = (date: Date, from: Date | null, to: Date | null) => {
    if (from && to) {
      return date >= from && date <= to;
    } else if (from) {
      return date >= from;
    } else if (to) {
      return date <= to;
    }
    return true;
  };

  const filteredAndSortedIssues = useMemo(() => {
    return localIssues
      .filter(issue => 
        issue.id.includes(searchTerm) || 
        issue.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        issue.tags?.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase()))
      )
      .filter(issue => 
        Object.entries(filters).every(([key, value]) => {
          if (key === 'createdOn' || key === 'lastUpdated') {
            if (!value || (!value.from && !value.to)) return true;
            const issueDate = new Date(issue[key as keyof Issue] as string);
            const fromDate = value.from ? new Date(value.from) : null;
            const toDate = value.to ? new Date(value.to) : null;
            return isDateInRange(issueDate, fromDate, toDate);
          }
          if (key === 'items') {
            if (Array.isArray(value) && value.length > 0) {
              if (value.includes('Has attachments') && !value.includes('Has comments')) {
                return issue.attachments.length > 0;
              }
              if (value.includes('Has comments') && !value.includes('Has attachments')) {
                return issue.conversations.length > 0;
              }
              if (value.includes('Has attachments') && value.includes('Has comments')) {
                return issue.attachments.length > 0 && issue.conversations.length > 0;
              }
            }
            return true;
          }
          if (key === 'assignedTo') {
            if (!issue.assignedTo || !Array.isArray(issue.assignedTo)) {
              return false;
            }
            return Array.isArray(value) && (value.length === 0 || value.some(userId => issue.assignedTo.includes(userId)));
          }
          if (key === 'tags') {
            if (!issue.tags || !Array.isArray(issue.tags)) {
              return false;
            }
            return Array.isArray(value) && (value.length === 0 || value.every(tag => issue.tags.includes(tag)));
          }
          if (Array.isArray(value) && value.length > 0) {
            return value.includes(String(issue[key as keyof Issue]));
          }
          if (typeof value === 'string' && value.length > 0) {
            return String(issue[key as keyof Issue]).toLowerCase().includes(value.toLowerCase());
          }
          return true;
        })
      )
      .filter(issue => !projectId || issue.projectId === projectId || (projectId === 'default-project-id' && !issue.projectId))
      .sort((a, b) => {
        const aValue = a[sortConfig.key as keyof Issue];
        const bValue = b[sortConfig.key as keyof Issue];
        if (aValue === undefined || bValue === undefined) return 0;
        if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
      });
  }, [localIssues, searchTerm, filters, sortConfig, projectId]);

  const handleNewIssueClick = () => {
    if (currentUser.permissions?.canCreateIssues) {
      setIsNewIssueDrawerOpen(true);
    } else {
      showSnackbar('You do not have permission to create issues', 'error');
    }
  };

  const handleNewIssueDrawerClose = () => {
    setIsNewIssueDrawerOpen(false);
    // Reset the form data if needed
    // You might want to add a resetForm function to your IssueForm component
    // and call it here
  };

  const handleAddIssue = async (issueData: IssueFormData): Promise<Issue> => {
    try {
      const newIssueData: Omit<Issue, 'id' | 'createdOn' | 'lastUpdated'> = {
        ...issueData,
        createdBy: currentUser.userId || '',
        isDeleted: false,
        conversations: [],
        lastEditedBy: currentUser.userId || '',
        lastEditedByName: `${currentUser.firstName} ${currentUser.lastName}`,
        createdByName: `${currentUser.firstName} ${currentUser.lastName}`,
        projectId: issueData.projectId || 'default-project-id',
        tasks: issueData.tasks.map(task => ({
          ...task,
          id: `task-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
          createdOn: new Date().toISOString(),
        })),
      };
      const createdIssue = await onAddIssue(newIssueData);
      setLocalIssues(prevIssues => [...prevIssues, createdIssue]);
      showSnackbar('Issue created successfully', 'success');
      
      // Close the new issue drawer
      setIsNewIssueDrawerOpen(false);
      
      // Redirect to the newly created issue's details page
      navigate(`/issues/?project=${createdIssue.projectId}&issue=${createdIssue.id}`);
      
      return createdIssue;
    } catch (error) {
      console.error("Error adding issue:", error);
      showSnackbar('Failed to create issue', 'error');
      throw error;
    }
  };

  const handleDuplicateIssue = async (issueData: Omit<Issue, 'id' | 'createdOn' | 'lastUpdated'>) => {
    try {
      const createdIssue = await onAddIssue(issueData);
      setLocalIssues(prevIssues => [...prevIssues, createdIssue]);
      showSnackbar('Issue duplicated successfully', 'success');
      return createdIssue;
    } catch (error) {
      console.error('Error duplicating issue:', error);
      showSnackbar('Failed to duplicate issue', 'error');
      throw error;
    }
  };

  const toggleViewMode = () => {
    setViewMode(prevMode => {
      if (prevMode === 'list') return 'card';
      if (prevMode === 'card') return 'kanban';
      return 'list';
    });
  };

  const handleEditIssue = (issue: Issue) => {
    if (currentUser.permissions?.canEditIssues) {
      onSelectIssue(issue);
    } else {
      showSnackbar('You do not have permission to edit issues', 'error');
    }
  };

  const handleCloseEditDrawer = () => {
    onSelectIssue(null);
  };

  const showSnackbar = (message: string, severity: 'success' | 'error') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const getActiveFilterCount = () => {
    return Object.values(filters).filter(value => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return value !== null && value !== undefined && value !== '';
    }).length;
  };

  const handleImageGalleryClick = () => {
    setIsImageGalleryOpen(true);
  };

  const handleTableClick = () => {
    if (projectId) {
      setIsTableLightboxOpen(true);
    } else {
      showSnackbar('Please select a project to view tables', 'error');
    }
  };

  const handleNotesClick = () => {
    if (projectId) {
      setIsNotesLightboxOpen(true);
    } else {
      showSnackbar('Please select a project to view notes', 'error');
    }
  };

  const handleUpdateIssue = async (updatedIssue: Issue) => {
    console.log('Updating issue in IssueListContainer:', updatedIssue);
    try {
      const issueWithEditInfo = {
        ...updatedIssue,
        lastEditedBy: currentUser.userId || '',
        lastEditedByName: `${currentUser.firstName} ${currentUser.lastName}`,
      };
      await onUpdateIssue(issueWithEditInfo);
      setLocalIssues(prevIssues => prevIssues.map(issue => 
        issue.id === issueWithEditInfo.id ? issueWithEditInfo : issue
      ));
      console.log('Issue updated successfully');
    } catch (error) {
      console.error("Error updating issue:", error);
    }
  };

  const handleIssueSelect = (issue: Issue) => {
    onSelectIssue(issue);
  };

  const handleSaveTable = async (updatedData: TableData) => {
    try {
      // Implement the logic to save the table data
      // This might involve calling an API or updating the state
      console.log('Saving table data:', updatedData);
      showSnackbar('Table saved successfully', 'success');
    } catch (error) {
      console.error('Error saving table:', error);
      showSnackbar('Failed to save table', 'error');
    }
  };

  return (
    <Box sx={{ width: '100%', overflowX: 'auto', p: 2 }}>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
        <Box display="flex" alignItems="center" sx={{ flexGrow: 1 }}>
          <IconButton onClick={handleImageGalleryClick} sx={{ mr: 1 }}>
            <ImageIcon />
          </IconButton>
          <IconButton onClick={handleNotesClick} sx={{ mr: 1 }}>
            <NotesIcon />
          </IconButton>
          <IconButton onClick={handleTableClick} sx={{ mr: 1 }}>
            <TableChartIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1, mx: 2 }}>
            <SearchIssues 
              onSearch={setSearchTerm} 
              placeholder="Search by Issue ID, Title or Tags"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          {currentUser.permissions?.canCreateIssues && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleNewIssueClick}
              sx={{ mr: 2 }}
            >
              New Issue
            </Button>
          )}
          {viewMode !== 'card' && (
            <Button
              variant="outlined"
              color="primary"
              startIcon={<ViewColumnIcon />}
              onClick={handleColumnSelectorClick}
              sx={{ mr: 2 }}
            >
              Columns
            </Button>
          )}
          <IconButton onClick={() => setIsFilterLightboxOpen(true)} sx={{ mr: 1 }}>
            <FilterListIcon />
          </IconButton>
          <IconButton onClick={toggleViewMode} sx={{ mr: 2 }}>
            {viewMode === 'list' && <ViewModuleIcon />}
            {viewMode === 'card' && <ViewKanbanIcon />}
            {viewMode === 'kanban' && <ViewListIcon />}
          </IconButton>
          <IconButton onClick={handleSortMenuOpen}>
            <SortIcon />
          </IconButton>
        </Box>
      </Box>

      {getActiveFilterCount() > 0 && (
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleClearAllFilters}
          sx={{ mb: 2 }}
        >
          Clear All {getActiveFilterCount()} Filters
        </Button>
      )}

      {viewMode === 'list' && (
        <IssueList
          issues={filteredAndSortedIssues}
          visibleColumns={visibleColumns}
          users={users}
          onSort={handleSortChange}
          sortConfig={sortConfig}
          onEdit={handleEditIssue}
          columnOrder={columnOrder}
          onIssueSelect={handleIssueSelect}
        />
      )}
      {viewMode === 'card' && (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '15px', justifyContent: 'flex-start' }}>
          {filteredAndSortedIssues.map(issue => (
            <Box key={issue.id} sx={{ width: 350, flexShrink: 0 }}>
              <IssueCard 
                issue={issue} 
                creator={users.find(u => u.userId === issue.createdBy)}
                onEdit={handleEditIssue}
                canEdit={currentUser.permissions?.canEditIssues || false}
                assignees={users.filter(u => issue.assignedTo?.includes(u.userId || ''))}
                onIssueSelect={handleIssueSelect}
              />
            </Box>
          ))}
        </Box>
      )}
      {viewMode === 'kanban' && (
        <KanbanBoard
          issues={filteredAndSortedIssues}
          onUpdateIssue={onUpdateIssue}
          onEditIssue={handleEditIssue}
          users={users}
          visibleColumns={visibleKanbanColumns}
          onIssueSelect={handleIssueSelect}
        />
      )}

      <ColumnSelector
        open={Boolean(columnSelectorAnchorEl)}
        anchorEl={columnSelectorAnchorEl}
        onClose={handleColumnSelectorClose}
        columns={viewMode === 'kanban' ? kanbanColumns : columnOrder}
        visibleColumns={viewMode === 'kanban' ? visibleKanbanColumns : visibleColumns}
        onToggle={handleColumnToggle}
        onReorder={handleColumnReorder}
        viewMode={viewMode}
      />

<FilterLightbox
        open={isFilterLightboxOpen}
        onClose={() => setIsFilterLightboxOpen(false)}
        filters={filters}
        visibleColumns={visibleColumns}
        onFilterChange={handleFilterChange}
        onClearAllFilters={handleClearAllFilters}
        allUsers={allUsers}
      />

      <SortMenu
        anchorEl={sortMenuAnchorEl}
        open={Boolean(sortMenuAnchorEl)}
        onClose={handleSortMenuClose}
        sortableColumns={sortableColumns}
        currentSort={sortConfig}
        onSortChange={handleSortChange}
      />

      <ImageGalleryLightbox
        open={isImageGalleryOpen}
        onClose={() => setIsImageGalleryOpen(false)}
        issues={localIssues}
        currentProject={projects.find(p => p.id === projectId) || null}
        currentUser={user!}
      />

      {projectId && isTableLightboxOpen && (
        <Dialog
          open={isTableLightboxOpen}
          onClose={() => setIsTableLightboxOpen(false)}
          fullScreen
          PaperProps={{
            style: {
              backgroundColor: 'white',
              boxShadow: 'none',
            },
          }}
        >
          <DialogContent sx={{ padding: 0, height: '100vh', width: '100vw', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ position: 'absolute', top: 16, right: 16, zIndex: 1 }}>
              <IconButton onClick={() => setIsTableLightboxOpen(false)} size="large">
                <CloseIcon />
              </IconButton>
            </Box>
            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
              <ErrorBoundary>
                <TableManager
                  projectId={projectId}
                  currentUser={currentUser}
                  onSaveTable={handleSaveTable}
                />
              </ErrorBoundary>
            </Box>
          </DialogContent>
        </Dialog>
      )}

      {projectId && isNotesLightboxOpen && (
        <NotesLightbox
          open={isNotesLightboxOpen}
          onClose={() => setIsNotesLightboxOpen(false)}
          projectId={projectId}
          currentUser={currentUser}
        />
      )}

      <Drawer
        anchor="right"
        open={isNewIssueDrawerOpen}
        onClose={handleNewIssueDrawerClose}
        sx={{
          '& .MuiDrawer-paper': {
            width: '70%',
            boxSizing: 'border-box',
          },
        }}
      >
        <Box sx={{ p: 3 }}>
          <IssueForm
            onSubmit={handleAddIssue}
            onCancel={handleNewIssueDrawerClose}
            users={users.filter(user => user.permissions?.canBeAssignedIssues)}
            currentUser={currentUser}
            projects={projects}
            currentProjectId={projectId}
            allTags={allTags}
          />
        </Box>
      </Drawer>

      <Drawer
        anchor="right"
        open={Boolean(selectedIssue)}
        onClose={handleCloseEditDrawer}
        sx={{
          '& .MuiDrawer-paper': {
            width: '70%',
            boxSizing: 'border-box',
          },
        }}
      >
        {selectedIssue && (
          <IssueDetails
            issue={selectedIssue}
            onUpdateIssue={handleUpdateIssue}
            onDuplicateIssue={handleDuplicateIssue}
            users={users.filter(user => user.permissions?.canBeAssignedIssues)}
            currentUser={currentUser}
            onClose={handleCloseEditDrawer}
            projects={projects}
            canEdit={currentUser.permissions?.canEditIssues || false}
            allTags={allTags}
          />
        )}
      </Drawer>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default IssueListContainer;