import React, { useEffect, useState } from 'react';
import { Grid, Typography, Paper, Box, Button } from '@mui/material';
import SummaryCard from './SummaryCard';
import IssueTrendChart from './IssueTrendChart';
import CompletionRateChart from './CompletionRateChart';
import StatusDistributionChart from './StatusDistributionChart';
import UserIssuesList from './UserIssuesList';
import ProjectsList from './ProjectsList';
import { fetchDashboardData } from '../../services/dashboardService';
import { Project, Issue } from '../../types';

interface DashboardProps {
  onClose: () => void;
}

interface ProjectWithIssues extends Project {
  openIssues: number;
  completedIssues: number;
  totalIssues: number;
  lastUpdated: string;
  issuesList: Issue[];
}

const Dashboard: React.FC<DashboardProps> = ({ onClose }) => {
  const [dashboardData, setDashboardData] = useState({
    totalProjects: 0,
    totalIssues: 0,
    openIssues: 0,
    completedIssues: 0,
    issueTrendData: [] as { date: string; count: number }[],
    completionRateData: [] as { name: string; value: number }[],
    statusDistributionData: [] as { name: string; value: number }[],
    projects: [] as ProjectWithIssues[],
    userEngagementData: [] as any[],
    userIssuesData: [] as {
      avatar: string;
      firstName: string;
      lastInitial: string;
      openIssues: number;
      completedIssues: number; // This is already correct
      createdIssues: number;
      lastUpdated: string;
      openIssuesList: { id: string; title: string; projectId: string; createdOn: string }[];
      completedIssuesList: { id: string; title: string; projectId: string; createdOn: string }[]; // This is already correct
      createdIssuesList: { id: string; title: string; projectId: string; createdOn: string }[];
    }[]
  });

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchDashboardData();
      setDashboardData(data);
    };

    fetchData();
  }, []);

  return (
    <Box sx={{ p: 3, backgroundColor: '#f5f5f5', height: '100%', overflowY: 'auto' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4">Dashboard</Typography>
        <Button variant="contained" color="primary" onClick={onClose}>
          Close Dashboard
        </Button>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <SummaryCard title="Total Projects" value={dashboardData.totalProjects} color="#4e79a7" />
        </Grid>
        <Grid item xs={12} md={3}>
          <SummaryCard title="Total Issues" value={dashboardData.totalIssues} color="#f28e2c" />
        </Grid>
        <Grid item xs={12} md={3}>
          <SummaryCard title="Open Issues" value={dashboardData.openIssues} color="#e15759" />
        </Grid>
        <Grid item xs={12} md={3}>
          <SummaryCard title="Completed Issues" value={dashboardData.completedIssues} color="#76b7b2" />
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>Issue Trend</Typography>
            <IssueTrendChart data={dashboardData.issueTrendData} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <UserIssuesList users={dashboardData.userIssuesData} />
          </Paper>
          <Paper sx={{ p: 2, mt: 3 }}>
            <ProjectsList projects={dashboardData.projects} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>Completion Rate</Typography>
                <CompletionRateChart data={dashboardData.completionRateData} />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>Status Distribution</Typography>
                <StatusDistributionChart data={dashboardData.statusDistributionData} />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;