import { Issue, StatusCounts, LevelCounts } from './types';

export const getStatusCounts = (issues: Issue[]): StatusCounts => {
  return issues.reduce((counts, issue) => {
    counts[issue.status] = (counts[issue.status] || 0) + 1;
    return counts;
  }, {} as StatusCounts);
};

export const getLevelCounts = (issues: Issue[]): LevelCounts => {
  return issues.reduce((counts, issue) => {
    counts[issue.level] = (counts[issue.level] || 0) + 1;
    return counts;
  }, {} as LevelCounts);
};

export const getTypeCounts = (issues: Issue[]): { Issue: number; Enhancement: number } => {
  return issues.reduce((counts, issue) => {
    counts[issue.type] = (counts[issue.type] || 0) + 1;
    return counts;
  }, { Issue: 0, Enhancement: 0 });
};

// Add any other utility functions here