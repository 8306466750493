import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Typography,
  Chip,
  Badge,
  AvatarGroup,
  Avatar,
  Tooltip,
} from '@mui/material';
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  Attachment as AttachmentIcon,
  Comment as CommentIcon,
} from '@mui/icons-material';
import { Issue, User, SortConfig } from '../types';
import '../styles/IssueList.css';

interface IssueListProps {
  issues: Issue[];
  visibleColumns: (keyof Issue | 'items')[];
  users: User[];
  onSort: (key: keyof Issue) => void;
  sortConfig: SortConfig;
  onEdit: (issue: Issue) => void;
  columnOrder: (keyof Issue | 'items')[];
  onIssueSelect: (issue: Issue) => void; // Add this line
}

const IssueList: React.FC<IssueListProps> = ({
  issues,
  visibleColumns,
  users,
  onSort,
  sortConfig,
  onEdit,
  columnOrder,
  onIssueSelect, // Add this line
}) => {
  const handleExpandClick = (issue: Issue) => {
    onEdit(issue);
  };

  const getChipColor = (value: string) => {
    const colorMap: { [key: string]: string } = {
      'Issue': '#FFB347',
      'Enhancement': '#87CEEB',
      'Open': '#ADD8E6',
      'In Process': '#E6E6FA',
      'Needs Info': '#FAFAD2',
      'Not Correct': '#FFA07A',
      'Completed': '#98FB98',
      'Blocking': '#FFC0CB',
      'Not Blocking': '#FAFAD2',
      'Design': '#A9A9A9',
      'Functionality': '#87CEFA',
      'Backend': '#DDA0DD',
    };
    return colorMap[value] || '#E0E0E0';
  };

  const stripHtml = (html: string) => {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + '...';
  };

  const renderCell = (issue: Issue, column: keyof Issue | 'items'): React.ReactNode => {
    if (column === 'items') {
      return (
        <Box display="flex" alignItems="center">
          {issue.attachments && issue.attachments.length > 0 && (
            <Badge
              badgeContent={issue.attachments.length}
              color="warning"
              sx={{
                '& .MuiBadge-badge': {
                  backgroundColor: 'orange',
                  color: 'white',
                },
              }}
            >
              <AttachmentIcon sx={{ color: 'gray' }} />
            </Badge>
          )}
          {issue.conversations && issue.conversations.length > 0 && (
            <Badge
              badgeContent={issue.conversations.length}
              color="primary"
              sx={{
                ml: 1,
                '& .MuiBadge-badge': {
                  backgroundColor: '#5595d4',
                  color: 'white',
                },
              }}
            >
              <CommentIcon sx={{ color: 'gray' }} />
            </Badge>
          )}
        </Box>
      );
    }

    switch (column) {
      case 'title':
        return <Typography fontWeight="bold">{issue.title}</Typography>;
      case 'description':
        return <Typography>{truncateText(stripHtml(issue.description || ''), 110)}</Typography>;
      case 'type':
      case 'status':
      case 'level':
        return <Chip label={issue[column] || ''} style={{ backgroundColor: getChipColor(issue[column] || '') }} />;
      case 'assignedTo':
        return (
          <AvatarGroup max={3} sx={{ justifyContent: 'flex-start' }}>
            {(issue.assignedTo || []).map(userId => {
              const user = users.find(u => u.userId === userId);
              return user ? (
                <Tooltip key={user.userId} title={`${user.firstName} ${user.lastName}`}>
                  <Avatar alt={user.firstName} src={user.avatarUrl} />
                </Tooltip>
              ) : null;
            })}
          </AvatarGroup>
        );
      case 'createdBy':
        {
          const user = users.find(u => u.userId === issue[column]);
          return user ? (
            <Tooltip title={`${user.firstName} ${user.lastName}`}>
              <Avatar src={user.avatarUrl} alt={user.firstName} />
            </Tooltip>
          ) : (
            <Avatar>?</Avatar>
          );
        }
      case 'createdOn':
      case 'lastUpdated':
        return new Date(issue[column] || '').toLocaleDateString();
      case 'tags':
        return (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {(issue.tags || []).map((tag, index) => (
              <Chip key={index} label={tag} size="small" />
            ))}
          </Box>
        );
      default:
        return String(issue[column] || '');
    }
  };

  const getColumnHeader = (column: keyof Issue | 'items'): string => {
    const headers: { [key: string]: string } = {
      id: 'ID',
      title: 'Title',
      description: 'Description',
      items: 'Items',
      type: 'Type',
      status: 'Status',
      level: 'Level',
      assignedTo: 'Assigned To',
      createdBy: 'Created By',
      createdOn: 'Created On',
      lastUpdated: 'Last Updated',
      tags: 'Tags',
    };
    return headers[column] || column;
  };

  const renderColumnHeader = (column: keyof Issue | 'items') => {
    const header = getColumnHeader(column);
    const isSortable = column !== 'items';
    const isSorted = sortConfig.key === column;

    return (
      <Box 
        sx={{ 
          display: 'flex',
          alignItems: 'center',
          cursor: isSortable ? 'pointer' : 'default',
        }}
        onClick={() => isSortable && onSort(column as keyof Issue)}
      >
        <Typography variant="subtitle2" component="span">
          {header}
        </Typography>
        {isSorted && (
          <Box sx={{ ml: 1 }}>
            {sortConfig.direction === 'asc' ? 
              <ArrowUpwardIcon fontSize="small" color="primary" /> : 
              <ArrowDownwardIcon fontSize="small" color="primary" />
            }
          </Box>
        )}
      </Box>
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '48px' }}></TableCell> {/* Static column for chevron */}
            {visibleColumns.map((column) => (
              <TableCell key={column}>
                {renderColumnHeader(column)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {issues.map((issue) => (
            <TableRow key={issue.id} onClick={() => onIssueSelect(issue)}>
              <TableCell>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => handleExpandClick(issue)}
                >
                  <KeyboardArrowLeftIcon />
                </IconButton>
              </TableCell>
              {columnOrder.filter(column => visibleColumns.includes(column)).map((column) => (
                <TableCell key={column}>
                  {renderCell(issue, column)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default IssueList;