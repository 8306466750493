import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyBIz_SHUjvXTTYqWmfizyoJAhyp2Y90wxc",
  authDomain: "issue-tracker-89f2f.firebaseapp.com",
  projectId: "issue-tracker-89f2f",
  storageBucket: "issue-tracker-89f2f.appspot.com",
  messagingSenderId: "118961811549",
  appId: "1:118961811549:web:8ef7a91b0711d1a9601db4",
  measurementId: "G-ZZNVHLS8N0"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app);

export const clientAuth = auth;
export const clientDb = firestore;

export const usersCollection = collection(firestore, 'users');
export const rolesCollection = collection(firestore, 'roles');
export const permissionsCollection = collection(firestore, 'permissions');