import React, { useState, useMemo } from 'react';
import { List, ListItem, ListItemAvatar, Avatar, ListItemText, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, Link, IconButton, Box, Menu, MenuItem } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import SortIcon from '@mui/icons-material/Sort';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import useLocalStorage from '../../hooks/useLocalStorage';

interface UserIssue {
  avatar: string;
  firstName: string;
  lastInitial: string;
  openIssues: number;
  completedIssues: number;
  createdIssues: number;
  lastUpdated: string;
  openIssuesList?: { id: string; title: string; projectId: string; createdOn: string }[];
  completedIssuesList?: { id: string; title: string; projectId: string; createdOn: string }[];
  createdIssuesList?: { id: string; title: string; projectId: string; createdOn: string }[];
}

interface UserIssuesListProps {
  users: UserIssue[];
}

type SortField = 'openIssues' | 'completedIssues' | 'createdIssues' | 'lastUpdated';

const UserIssuesList: React.FC<UserIssuesListProps> = ({ users }) => {
  const [selectedIssues, setSelectedIssues] = useState<{ id: string; title: string; projectId: string; createdOn: string }[]>([]);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [lightboxTitle, setLightboxTitle] = useState('');
  const [sortField, setSortField] = useLocalStorage<SortField | null>('userIssuesListSortField', null);
  const [sortDirection, setSortDirection] = useLocalStorage<'asc' | 'desc'>('userIssuesListSortDirection', 'desc');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleIssueClick = (issues: { id: string; title: string; projectId: string; createdOn: string }[], type: 'Open' | 'Completed' | 'Created', userName: string) => {
    setSelectedIssues(issues);
    setLightboxTitle(`${type} Issues for ${userName}`);
    setIsLightboxOpen(true);
  };

  const handleSortMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSortMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSort = (field: SortField) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('desc');
    }
    handleSortMenuClose();
  };

  const sortedUsers = useMemo(() => {
    if (!sortField) return users;
    return [...users].sort((a, b) => {
      if (a[sortField] < b[sortField]) return sortDirection === 'asc' ? -1 : 1;
      if (a[sortField] > b[sortField]) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  }, [users, sortField, sortDirection]);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">User Issues</Typography>
        <IconButton onClick={handleSortMenuOpen} size="small">
          <SortIcon />
          {sortField && (
            <Box component="span" sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
              {sortField === 'openIssues' && 'Open'}
              {sortField === 'completedIssues' && 'Completed'}
              {sortField === 'createdIssues' && 'Created'}
              {sortField === 'lastUpdated' && 'Last Updated'}
              {sortDirection === 'asc' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />}
            </Box>
          )}
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleSortMenuClose}
      >
        <MenuItem onClick={() => handleSort('openIssues')}>Open Issues</MenuItem>
        <MenuItem onClick={() => handleSort('completedIssues')}>Completed Issues</MenuItem>
        <MenuItem onClick={() => handleSort('createdIssues')}>Created Issues</MenuItem>
        <MenuItem onClick={() => handleSort('lastUpdated')}>Last Updated</MenuItem>
      </Menu>
      <List>
        {sortedUsers.map((user, index) => (
          <ListItem key={index}>
            <ListItemAvatar>
              <Avatar src={user.avatar} alt={`${user.firstName} ${user.lastInitial}.`} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography component="span" variant="body1" fontWeight="bold">
                    {`${user.firstName} ${user.lastInitial}.`}
                  </Typography>
                  {' '}
                  <Button onClick={() => handleIssueClick(user.openIssuesList || [], 'Open', `${user.firstName} ${user.lastInitial}.`)}>
                    Open: {user.openIssues}
                  </Button>
                  {' '}
                  <Button onClick={() => handleIssueClick(user.completedIssuesList || [], 'Completed', `${user.firstName} ${user.lastInitial}.`)}>
                    Completed: {user.completedIssues}
                  </Button>
                  {' '}
                  <Button onClick={() => handleIssueClick(user.createdIssuesList || [], 'Created', `${user.firstName} ${user.lastInitial}.`)}>
                    Created: {user.createdIssues}
                  </Button>
                </React.Fragment>
              }
              secondary={
                <Typography component="span" variant="body2">
                  Last updated an issue on: {new Date(user.lastUpdated).toLocaleString()}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>
      <Dialog open={isLightboxOpen} onClose={() => setIsLightboxOpen(false)}>
        <DialogTitle>{lightboxTitle}</DialogTitle>
        <DialogContent>
          <List>
            {selectedIssues.map((issue) => (
              <ListItem key={issue.id}>
                <Typography>
                  • <Link component={RouterLink} to={`/issues?project=${issue.projectId}&issue=${issue.id}`} target="_blank" rel="noopener noreferrer">
                    {issue.title}
                  </Link>
                  {' '}
                  <Typography component="span" variant="body2">
                    (Created on: {new Date(issue.createdOn).toLocaleString()})
                  </Typography>
                </Typography>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsLightboxOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserIssuesList;