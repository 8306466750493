import React from 'react';
import { DragDropContext, Droppable, Draggable, DropResult, DroppableProvided, DraggableProvided } from 'react-beautiful-dnd';
import { Box, Popover, Typography, Checkbox, FormControlLabel } from '@mui/material';
import { DragIndicator as DragIndicatorIcon } from '@mui/icons-material';

interface ColumnSelectorProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  columns: string[];
  visibleColumns: string[];
  onToggle: (column: string) => void;
  onReorder: (result: DropResult) => void;
  viewMode: 'list' | 'card' | 'kanban';
}

const ColumnSelector: React.FC<ColumnSelectorProps> = ({
  open,
  anchorEl,
  onClose,
  columns,
  visibleColumns,
  onToggle,
  onReorder,
  viewMode,
}) => {
  if (viewMode === 'card') {
    return null;
  }

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    onReorder(result);
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Box sx={{ p: 2, width: 250 }}>
        <Typography variant="h6" gutterBottom>
          {viewMode === 'kanban' ? 'Kanban Columns' : 'Table Columns'}
        </Typography>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="column-list">
            {(provided: DroppableProvided) => (
              <Box
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {columns.map((column, index) => (
                  <Draggable key={column} draggableId={column} index={index}>
                    {(provided: DraggableProvided) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          mb: 1,
                          userSelect: 'none',
                        }}
                      >
                        <DragIndicatorIcon sx={{ mr: 1, color: 'text.secondary' }} />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={visibleColumns.includes(column)}
                              onChange={() => onToggle(column)}
                              name={column}
                            />
                          }
                          label={column}
                        />
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    </Popover>
  );
};

export default ColumnSelector;