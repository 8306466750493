import React, { useState, useMemo } from 'react';
import { List, ListItem, ListItemText, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, Link, IconButton, Box, Menu, MenuItem, ListItemIcon, Avatar } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import SortIcon from '@mui/icons-material/Sort';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import WorkIcon from '@mui/icons-material/Work'; // Import the default project icon
import useLocalStorage from '../../hooks/useLocalStorage';
import { Project, Issue } from '../../types';

interface ProjectWithIssues extends Project {
  openIssues: number;
  completedIssues: number;
  totalIssues: number;
  lastUpdated: string;
  issuesList: Issue[];
}

interface ProjectsListProps {
  projects: ProjectWithIssues[];
}

type SortField = 'name' | 'openIssues' | 'completedIssues' | 'totalIssues' | 'lastUpdated';

const ProjectsList: React.FC<ProjectsListProps> = ({ projects }) => {
  const [selectedIssues, setSelectedIssues] = useState<Issue[]>([]);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [lightboxTitle, setLightboxTitle] = useState('');
  const [sortField, setSortField] = useLocalStorage<SortField>('projectsListSortField', 'name');
  const [sortDirection, setSortDirection] = useLocalStorage<'asc' | 'desc'>('projectsListSortDirection', 'asc');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleIssueClick = (issues: Issue[], type: 'Open' | 'Completed' | 'All', projectName: string) => {
    setSelectedIssues(issues.filter(issue => 
      type === 'All' || 
      (type === 'Open' && issue.status !== 'Completed') || 
      (type === 'Completed' && issue.status === 'Completed')
    ));
    setLightboxTitle(`${type} Issues for ${projectName}`);
    setIsLightboxOpen(true);
  };

  const handleSortMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSortMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSort = (field: SortField) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
    handleSortMenuClose();
  };

  const sortedProjects = useMemo(() => {
    return [...projects].sort((a, b) => {
      if (a[sortField] < b[sortField]) return sortDirection === 'asc' ? -1 : 1;
      if (a[sortField] > b[sortField]) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  }, [projects, sortField, sortDirection]);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Projects</Typography>
        <IconButton onClick={handleSortMenuOpen} size="small">
          <SortIcon />
          <Box component="span" sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
            {sortField === 'name' && 'Name'}
            {sortField === 'openIssues' && 'Open'}
            {sortField === 'completedIssues' && 'Completed'}
            {sortField === 'totalIssues' && 'Total'}
            {sortField === 'lastUpdated' && 'Last Updated'}
            {sortDirection === 'asc' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />}
          </Box>
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleSortMenuClose}
      >
        <MenuItem onClick={() => handleSort('name')}>Name</MenuItem>
        <MenuItem onClick={() => handleSort('openIssues')}>Open Issues</MenuItem>
        <MenuItem onClick={() => handleSort('completedIssues')}>Completed Issues</MenuItem>
        <MenuItem onClick={() => handleSort('totalIssues')}>Total Issues</MenuItem>
        <MenuItem onClick={() => handleSort('lastUpdated')}>Last Updated</MenuItem>
      </Menu>
      <List>
        {sortedProjects.map((project) => (
          <ListItem key={project.id}>
            <ListItemIcon>
              {project.icon ? (
                <Avatar src={project.icon} alt={project.name} />
              ) : (
                <WorkIcon color="primary" />
              )}
            </ListItemIcon>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography component="span" variant="body1" fontWeight="bold">
                    {project.name}
                  </Typography>
                  {' '}
                  <Button onClick={() => handleIssueClick(project.issuesList.filter(issue => issue.status !== 'Completed'), 'Open', project.name)}>
                    Open: {project.openIssues}
                  </Button>
                  {' '}
                  <Button onClick={() => handleIssueClick(project.issuesList.filter(issue => issue.status === 'Completed'), 'Completed', project.name)}>
                    Completed: {project.completedIssues}
                  </Button>
                  {' '}
                  <Button onClick={() => handleIssueClick(project.issuesList, 'All', project.name)}>
                    Total: {project.totalIssues}
                  </Button>
                </React.Fragment>
              }
              secondary={
                <Typography component="span" variant="body2">
                  Last issue updated on: {new Date(project.lastUpdated).toLocaleString()}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>
      <Dialog open={isLightboxOpen} onClose={() => setIsLightboxOpen(false)}>
        <DialogTitle>{lightboxTitle}</DialogTitle>
        <DialogContent>
          <List>
            {selectedIssues.map((issue) => (
              <ListItem key={issue.id}>
                <Typography>
                  • <Link component={RouterLink} to={`/issues?project=${issue.projectId}&issue=${issue.id}`} target="_blank" rel="noopener noreferrer">
                    {issue.title}
                  </Link>
                  {' '}
                  <Typography component="span" variant="body2">
                    (Created on: {new Date(issue.createdOn).toLocaleString()})
                  </Typography>
                </Typography>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsLightboxOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProjectsList;