import React from 'react';
import { Paper, Typography, Box } from '@mui/material';

interface SummaryCardProps {
  title: string;
  value: number;
  color: string;
}

const SummaryCard: React.FC<SummaryCardProps> = ({ title, value, color }) => {
  return (
    <Paper sx={{ p: 2, height: '100%', backgroundColor: color, color: 'white' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="h3">{value}</Typography>
      </Box>
    </Paper>
  );
};

export default SummaryCard;