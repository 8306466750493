import React from 'react';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import { SortConfig } from '../types';

interface SortMenuProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  sortableColumns: string[];
  currentSort: SortConfig;
  onSortChange: (key: string) => void;
}

const SortMenu: React.FC<SortMenuProps> = ({
  anchorEl,
  open,
  onClose,
  sortableColumns,
  currentSort,
  onSortChange,
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
    >
      {sortableColumns.map((column) => (
        <MenuItem key={column} onClick={() => onSortChange(column)}>
          <ListItemIcon>
            {currentSort.key === column ? (
              currentSort.direction === 'asc' ? <ArrowUpward /> : <ArrowDownward />
            ) : null}
          </ListItemIcon>
          <ListItemText primary={column} />
        </MenuItem>
      ))}
    </Menu>
  );
};

export default SortMenu;