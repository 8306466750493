import React, { useState, useMemo, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, List, ListItem, ListItemText, Typography, Box, FormControl, InputLabel, Select, MenuItem, IconButton, Menu, Checkbox, ListItemIcon, CircularProgress, Link, Avatar } from '@mui/material';
import { IssueUpdate, Project } from '../types';
import { format } from 'date-fns';
import FilterListIcon from '@mui/icons-material/FilterList';
import FolderIcon from '@mui/icons-material/Folder';
import { getGlobalUpdates, getIssueUpdates, subscribeToGlobalUpdates, subscribeToIssueUpdates } from '../issueService';

interface UpdateHistoryLightboxProps {
  open: boolean;
  onClose: () => void;
  updates: IssueUpdate[];
  isGlobalView?: boolean;
  projects?: Project[];
  selectedProject?: Project | null;
  isLoading: boolean;
  issueId?: string;
}

const UpdateHistoryLightbox: React.FC<UpdateHistoryLightboxProps> = ({
  open,
  onClose,
  updates,
  isGlobalView = false,
  projects = [],
  selectedProject = null,
  isLoading,
  issueId
}) => {
  console.log('UpdateHistoryLightbox props:', { open, updates, isGlobalView, projects, selectedProject, isLoading });

  const [groupBy, setGroupBy] = useState<'none' | 'username' | 'changeType'>('none');
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedUserNames, setSelectedUserNames] = useState<string[]>([]);
  const [selectedChangeTypes, setSelectedChangeTypes] = useState<string[]>([]);
  const [selectedProjectId, setSelectedProjectId] = useState<string | null>(selectedProject?.id || null);
  const [cachedUpdates, setCachedUpdates] = useState<IssueUpdate[]>([]);

  const [localIsLoading, setLocalIsLoading] = useState(isLoading);

  useEffect(() => {
    const fetchInitialUpdates = async () => {
      setLocalIsLoading(true);
      try {
        const initialUpdates = isGlobalView
          ? await getGlobalUpdates()
          : await getIssueUpdates(issueId || '');
        setCachedUpdates(initialUpdates);
      } catch (error) {
        console.error("Error fetching initial updates:", error);
      } finally {
        setLocalIsLoading(false);
      }
    };

    fetchInitialUpdates();

    const unsubscribe = isGlobalView
      ? subscribeToGlobalUpdates(projects, (newUpdates) => {
          setCachedUpdates(prevUpdates => {
            const combinedUpdates = [...newUpdates, ...prevUpdates];
            // Remove duplicates and sort
            return Array.from(new Set(combinedUpdates.map(u => u.id)))
              .map(id => combinedUpdates.find(u => u.id === id)!)
              .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
          });
        })
      : subscribeToIssueUpdates(issueId || '', (newUpdates) => {
          setCachedUpdates(prevUpdates => {
            const combinedUpdates = [...newUpdates, ...prevUpdates];
            // Remove duplicates and sort
            return Array.from(new Set(combinedUpdates.map(u => u.id)))
              .map(id => combinedUpdates.find(u => u.id === id)!)
              .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
          });
        });

    return () => {
      if (typeof unsubscribe === "function") {
        unsubscribe();
      }
    };
  }, [isGlobalView, projects, issueId]);

  const formatUpdateTime = (timestamp: string) => {
    return format(new Date(timestamp), 'MMM d, yyyy, h:mm a');
  };

  const stripHtmlTags = (str: string | undefined) => {
    if (typeof str !== 'string') return '';
    return str.replace(/<[^>]*>/g, '');
  };

  const uniqueUserNames = useMemo(() => [...new Set(cachedUpdates.map(update => update.userName))], [cachedUpdates]);
  const uniqueChangeTypes = useMemo(() => [...new Set(cachedUpdates.flatMap(update => Object.values(update.changes).map(change => change.label)))], [cachedUpdates]);

  const filteredUpdates = useMemo(() => {
    const filtered = cachedUpdates.filter(update => 
      (selectedUserNames.length === 0 || selectedUserNames.includes(update.userName)) &&
      (selectedChangeTypes.length === 0 || Object.values(update.changes).some(change => selectedChangeTypes.includes(change.label))) &&
      (!selectedProjectId || update.projectId === selectedProjectId)
    );
    console.log('Filtered updates:', filtered);
    return filtered;
  }, [cachedUpdates, selectedUserNames, selectedChangeTypes, selectedProjectId]);

  const groupedUpdates = useMemo(() => {
    if (groupBy === 'none') return { '': filteredUpdates };
    return filteredUpdates.reduce((acc, update) => {
      const key = groupBy === 'username' ? update.userName : Object.values(update.changes)[0].label;
      if (!acc[key]) acc[key] = [];
      acc[key].push(update);
      return acc;
    }, {} as Record<string, IssueUpdate[]>);
  }, [filteredUpdates, groupBy]);

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleUserNameToggle = (userName: string) => {
    setSelectedUserNames(prev => 
      prev.includes(userName) ? prev.filter(name => name !== userName) : [...prev, userName]
    );
  };

  const handleChangeTypeToggle = (changeType: string) => {
    setSelectedChangeTypes(prev => 
      prev.includes(changeType) ? prev.filter(type => type !== changeType) : [...prev, changeType]
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {isGlobalView ? 'Global Update History' : 'Update History'}
          <Box display="flex" alignItems="center">
            {isGlobalView && (
              <FormControl size="small" sx={{ minWidth: 120, mr: 1 }}>
                <InputLabel id="project-filter-label">Project</InputLabel>
                <Select
                  labelId="project-filter-label"
                  value={selectedProjectId || ''}
                  label="Project"
                  onChange={(e) => setSelectedProjectId(e.target.value as string)}
                >
                  <MenuItem value="">All Projects</MenuItem>
                  {projects.map((project) => (
                    <MenuItem key={project.id} value={project.id}>{project.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <FormControl size="small" sx={{ minWidth: 120, mr: 1 }}>
              <InputLabel id="group-by-label">Group By</InputLabel>
              <Select
                labelId="group-by-label"
                value={groupBy}
                label="Group By"
                onChange={(e) => setGroupBy(e.target.value as 'none' | 'username' | 'changeType')}
              >
                <MenuItem value="none">None</MenuItem>
                <MenuItem value="username">Username</MenuItem>
                <MenuItem value="changeType">Change Type</MenuItem>
              </Select>
            </FormControl>
            <IconButton onClick={handleFilterClick}>
              <FilterListIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        {localIsLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="200px">
            <CircularProgress />
          </Box>
        ) : cachedUpdates.length > 0 ? (
          <List>
            {Object.entries(groupedUpdates).map(([group, groupUpdates]) => (
              <React.Fragment key={group}>
                {group && <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>{group}</Typography>}
                {groupUpdates.map((update) => {
                  const project = projects.find(p => p.id === update.projectId);
                  return (
                    <React.Fragment key={update.id}>
                      <ListItem>
                        <ListItemText
                          primary={
                            <Box>
                              <Typography variant="subtitle1" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                                {project?.icon ? (
                                  <Avatar src={project.icon} sx={{ width: 24, height: 24, mr: 1 }} />
                                ) : (
                                  <FolderIcon sx={{ mr: 1 }} />
                                )}
                                {project?.name || 'Unknown Project'} | {' '}
                                <Link 
                                  href={`/issues/?project=${update.projectId}&issue=${update.issueId}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  sx={{ ml: 1 }}
                                >
                                  {update.issueTitle || 'Unknown Issue'}
                                </Link>
                                <Typography variant="caption" component="span" sx={{ ml: 1, color: 'text.secondary' }}>
                                  (Update id: {update.id})
                                </Typography>
                              </Typography>
                            </Box>
                          }
                          secondary={
                            <>
                              {Object.entries(update.changes).map(([key, change]) => (
                                <Typography key={key} variant="body2">
                                  • {stripHtmlTags(change.label)}
                                </Typography>
                              ))}
                              <Typography variant="body2" sx={{ mt: 1 }}>
                                Updated by: {update.userName} on {formatUpdateTime(update.timestamp)}
                              </Typography>
                            </>
                          }
                        />
                      </ListItem>
                      <Box sx={{ borderBottom: '1px solid #e0e0e0', width: '100%', my: 2 }} />
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            ))}
          </List>
        ) : (
          <Typography variant="body1" align="center">No updates found. This could be because no changes have been made to any issues, or there might be an error fetching the updates.</Typography>
        )}
      </DialogContent>
      <Menu
        anchorEl={filterAnchorEl}
        open={Boolean(filterAnchorEl)}
        onClose={handleFilterClose}
      >
        <MenuItem disabled>Filter by User Name</MenuItem>
        {uniqueUserNames.map(userName => (
          <MenuItem key={userName} onClick={() => handleUserNameToggle(userName)}>
            <ListItemIcon>
              <Checkbox checked={selectedUserNames.includes(userName)} />
            </ListItemIcon>
            <ListItemText primary={userName} />
          </MenuItem>
        ))}
        <MenuItem disabled>Filter by Change Type</MenuItem>
        {uniqueChangeTypes.map(changeType => (
          <MenuItem key={changeType} onClick={() => handleChangeTypeToggle(changeType)}>
            <ListItemIcon>
              <Checkbox checked={selectedChangeTypes.includes(changeType)} />
            </ListItemIcon>
            <ListItemText primary={changeType} />
          </MenuItem>
        ))}
      </Menu>
    </Dialog>
  );
};

export default UpdateHistoryLightbox;