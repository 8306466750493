import React from 'react';
import { Box, Typography, Chip } from '@mui/material';
import { StatusCounts, LevelCounts } from '../types';

interface IssueHeaderProps {
  statusCounts: StatusCounts;
  levelCounts: LevelCounts;
  typeCounts: { Issue: number; Enhancement: number };
}

const IssueHeader: React.FC<IssueHeaderProps> = ({ 
  statusCounts, 
  levelCounts, 
  typeCounts
}) => {
  const getChipColor = (label: string) => {
    switch (label) {
      case 'Blocking': return '#FFC0CB'; // Pinkish red
      case 'Design': return '#A9A9A9'; // Gray
      case 'In Process': return '#E6E6FA';
      case 'Completed': return '#98FB98';
      case 'Open': return '#ADD8E6';
      case 'Not Blocking': return '#FAFAD2';
      case 'Functionality': return '#87CEFA';
      case 'Backend': return '#DDA0DD';
      case 'Issue': return '#FFB347';
      case 'Enhancement': return '#87CEEB';
      default: return '#E0E0E0';
    }
  };

  const getChipTextColor = (label: string) => {
    switch (label) {
      case 'Blocking': return '#FFFFFF';
      case 'Design': return '#000000';
      default: return '#000000';
    }
  };

  return (
    <Box sx={{ mb: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Box>
          <Typography variant="subtitle1">Type</Typography>
          {Object.entries(typeCounts).map(([type, count]) => (
            <Chip
              key={type}
              label={`${type}: ${count}`}
              sx={{
                mr: 1,
                bgcolor: getChipColor(type),
                color: getChipTextColor(type)
              }}
            />
          ))}
        </Box>
        <Box>
          <Typography variant="subtitle1">Priority</Typography>
          {Object.entries(levelCounts).map(([level, count]) => (
            <Chip
              key={level}
              label={`${level}: ${count}`}
              sx={{
                mr: 1,
                bgcolor: getChipColor(level),
                color: getChipTextColor(level)
              }}
            />
          ))}
        </Box>
        <Box>
          <Typography variant="subtitle1">Status</Typography>
          {Object.entries(statusCounts).map(([status, count]) => (
            <Chip
              key={status}
              label={`${status}: ${count}`}
              sx={{
                mr: 1,
                bgcolor: getChipColor(status),
                color: getChipTextColor(status)
              }}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default IssueHeader;