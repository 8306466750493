import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  SelectChangeEvent,
  Avatar,
  FormHelperText,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Card,
  CardContent,
  Autocomplete,
  Chip
} from '@mui/material';
import { Issue, User, IssueFormData, Project, Task } from '../types';
import FileUploadArea from './FileUploadArea';
import FilePreview from './FilePreview';
import { Editor, initTinyMCE } from '../utils/tinymceSetup';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { fileService } from '../fileService';
import { notificationsService } from '../services/notificationsService';

interface IssueFormProps {
  issue?: Partial<Issue>;
  onSubmit: (issue: IssueFormData, files: File[]) => Promise<Issue>;
  onCancel: () => void;
  users: User[];
  currentUser: User;
  projects: Project[];
  currentProjectId: string | null;
  allTags: string[];
}

const IssueForm: React.FC<IssueFormProps> = ({ 
  issue, 
  onSubmit, 
  onCancel, 
  users,
  currentUser,
  projects,
  currentProjectId,
  allTags
}) => {
  const [formData, setFormData] = useState<IssueFormData>({
    title: '',
    description: '',
    type: 'Issue',
    status: 'Open',
    level: 'Not Blocking',
    assignedTo: [],
    attachments: [],
    projectId: currentProjectId || 'default-project-id',
    tasks: [],
    tags: []
  });
  const [files, setFiles] = useState<File[]>([]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [newTask, setNewTask] = useState('');

  useEffect(() => {
    if (issue) {
      setFormData(prevState => ({
        ...prevState,
        ...issue,
        attachments: issue.attachments || [],
        conversations: issue.conversations || [],
        projectId: issue.projectId || currentProjectId || 'default-project-id'
      }));
      setTasks(issue.tasks || []);
    } else {
      resetForm();
    }
  }, [issue, currentProjectId]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string | string[]>
  ) => {
    const { name, value } = e.target;
    setFormData(prev => ({ 
      ...prev, 
      [name]: Array.isArray(value) ? value : value 
    }));
    setErrors(prev => ({ ...prev, [name]: '' }));
  };

  const handleEditorChange = (content: string) => {
    setFormData(prev => ({ ...prev, description: content }));
    setErrors(prev => ({ ...prev, description: '' }));
  };

  const handleFileUpload = async (uploadedFiles: File[]) => {
    const newFiles = [...files, ...uploadedFiles];
    setFiles(newFiles);
  };

  const handleRemoveFile = async (index: number) => {
    const fileToRemove = files[index];
    if (fileToRemove instanceof File) {
      setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    } else {
      try {
        await fileService.deleteFile(fileToRemove as string);
        setFormData(prev => ({
          ...prev,
          attachments: prev.attachments.filter((_, i) => i !== index)
        }));
      } catch (error) {
        console.error('Error removing file:', error);
        // Handle error (e.g., show a snackbar)
      }
    }
  };

  const handleAddTask = () => {
    if (newTask.trim()) {
      setTasks(prev => [...prev, {
        id: `task-${Date.now()}`,
        text: newTask.trim(),
        completed: false,
        createdOn: new Date().toISOString()
      }]);
      setNewTask('');
    }
  };

  const handleRemoveTask = (taskId: string) => {
    setTasks(prev => prev.filter(task => task.id !== taskId));
  };

  const handleToggleTask = (taskId: string) => {
    setTasks(prev => prev.map(task => 
      task.id === taskId ? { ...task, completed: !task.completed } : task
    ));
  };

  const validateForm = (): boolean => {
    const newErrors: { [key: string]: string } = {};
    let isValid = true;

    if (!formData.title.trim()) {
      newErrors.title = 'Title is required';
      isValid = false;
    }
    if (!formData.description.trim()) {
      newErrors.description = 'Description is required';
      isValid = false;
    }
    if (formData.type !== 'Issue' && formData.type !== 'Enhancement') {
      newErrors.type = 'Type is required';
      isValid = false;
    }
    if (!['Open', 'In Process', 'Needs Info', 'Not Correct', 'Completed'].includes(formData.status)) {
      newErrors.status = 'Status is required';
      isValid = false;
    }
    if (!['Blocking', 'Not Blocking', 'Design', 'Functionality', 'Backend'].includes(formData.level)) {
      newErrors.level = 'Level is required';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<Issue | void> => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitting(true);
      try {
        const issueId = issue?.id || `new-issue-${Date.now()}`;
        const uploadedFileUrls = await Promise.all(
          files.map(file => fileService.uploadFile(file, issueId))
        );

        const issueData: IssueFormData = {
          ...formData,
          tasks: tasks.map(task => ({
            id: task.id,
            text: task.text,
            completed: task.completed,
            createdOn: task.createdOn || new Date().toISOString(),
            completedOn: task.completedOn || null,
            editedAt: task.editedAt || null
          })),
          attachments: [...formData.attachments, ...uploadedFileUrls],
          lastEditedBy: currentUser.userId || '',
          lastEditedByName: `${currentUser.firstName} ${currentUser.lastName}`,
          createdByName: `${currentUser.firstName} ${currentUser.lastName}`,
        };

        const createdIssue = await onSubmit(issueData, []);

        // Create notification for new issue
        if (!issue) {
          await notificationsService.createIssueUpdateNotification(
            createdIssue.id,
            createdIssue.projectId,
            currentUser.userId || '',
            `${currentUser.firstName} ${currentUser.lastName}`,
            createdIssue.title,
            'New issue created'
          );
        }

        setIsSubmitting(false);
        setFiles([]);
        
        return createdIssue;
      } catch (error) {
        console.error('Error submitting issue:', error);
        setIsSubmitting(false);
        throw error;
      }
    }
    return;
  };

  const handleTagChange = (_: React.SyntheticEvent, newValue: string[]) => {
    setFormData(prev => ({ ...prev, tags: newValue }));
  };

  const resetForm = () => {
    setFormData({
      title: '',
      description: '',
      type: 'Issue',
      status: 'Open',
      level: 'Not Blocking',
      assignedTo: [],
      attachments: [],
      projectId: currentProjectId || 'default-project-id',
      tasks: [],
      tags: []
    });
    setFiles([]);
    setTasks([]);
    setErrors({});
  };

  const activeProjects = projects.filter(project => !project.isArchived);

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
          <Avatar src={currentUser.avatarUrl} alt={currentUser.firstName} sx={{ mr: 2 }} />
            <Box>
              <Typography variant="subtitle1">
                Created by: {`${currentUser.firstName} ${currentUser.lastName}`}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {new Date().toLocaleString()}
              </Typography>
            </Box>
          </Box>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="project-label">Project</InputLabel>
            <Select
              labelId="project-label"
              id="projectId"
              name="projectId"
              value={formData.projectId}
              onChange={handleChange}
              label="Project"
            >
              <MenuItem value="default-project-id">Default (No Project)</MenuItem>
              {activeProjects.filter(project => project.id !== 'default-project-id').map((project) => (
                <MenuItem key={project.id} value={project.id}>
                  {project.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="title"
            label="Title"
            value={formData.title}
            onChange={handleChange}
            required
            error={!!errors.title}
            helperText={errors.title}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>Description</Typography>
          <Editor
            init={initTinyMCE}
            value={formData.description}
            onEditorChange={handleEditorChange}
          />
          {errors.description && (
            <Typography color="error" variant="caption">
              {errors.description}
            </Typography>
          )}
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth error={!!errors.type}>
            <InputLabel id="type-label">Type</InputLabel>
            <Select
              labelId="type-label"
              id="type"
              name="type"
              value={formData.type}
              onChange={handleChange}
              label="Type"
            >
              <MenuItem value="Issue">Issue</MenuItem>
              <MenuItem value="Enhancement">Enhancement</MenuItem>
            </Select>
            {errors.type && <FormHelperText>{errors.type}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth error={!!errors.status}>
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              id="status"
              name="status"
              value={formData.status}
              onChange={handleChange}
              label="Status"
            >
              <MenuItem value="Open">Open</MenuItem>
              <MenuItem value="In Process">In Process</MenuItem>
              <MenuItem value="Needs Info">Needs Info</MenuItem>
              <MenuItem value="Not Correct">Not Correct</MenuItem>
              <MenuItem value="Completed">Completed</MenuItem>
            </Select>
            {errors.status && <FormHelperText>{errors.status}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth error={!!errors.level}>
            <InputLabel id="level-label">Level</InputLabel>
            <Select
              labelId="level-label"
              id="level"
              name="level"
              value={formData.level}
              onChange={handleChange}
              label="Level"
            >
              <MenuItem value="Blocking">Blocking</MenuItem>
              <MenuItem value="Not Blocking">Not Blocking</MenuItem>
              <MenuItem value="Design">Design</MenuItem>
              <MenuItem value="Functionality">Functionality</MenuItem>
              <MenuItem value="Backend">Backend</MenuItem>
            </Select>
            {errors.level && <FormHelperText>{errors.level}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Attachments</Typography>
          <FileUploadArea onUpload={handleFileUpload} />
          <Box display="flex" flexWrap="wrap" gap={2} mt={2}>
            {[...formData.attachments, ...files].map((file, index) => (
              <FilePreview
                key={index}
                file={file}
                onRemove={() => handleRemoveFile(index)}
              />
            ))}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Card elevation={3} sx={{ mb: 2, bgcolor: 'white' }}>
            <CardContent>
              <Typography variant="subtitle1" gutterBottom>Sub Tasks</Typography>
              <List>
                {tasks.map((task) => (
                  <ListItem
                    key={task.id}
                    disablePadding
                    secondaryAction={
                      <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveTask(task.id)}>
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemIcon>
                      <DragIndicatorIcon />
                    </ListItemIcon>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={task.completed}
                        onChange={() => handleToggleTask(task.id)}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={task.text}
                      sx={{ textDecoration: task.completed ? 'line-through' : 'none' }}
                    />
                  </ListItem>
                ))}
              </List>
              <Box display="flex" alignItems="center" mt={2}>
                <TextField
                  fullWidth
                  value={newTask}
                  onChange={(e) => setNewTask(e.target.value)}
                  placeholder="Type task"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      handleAddTask();
                    }
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddTask}
                  sx={{ ml: 1, whiteSpace: 'nowrap' }}
                >
                  Add Tasks
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="assigned-to-label">Assigned To</InputLabel>
            <Select
              labelId="assigned-to-label"
              id="assignedTo"
              name="assignedTo"
              multiple
              value={formData.assignedTo}
              onChange={handleChange}
              label="Assigned To"
            >
              {users.map((user) => (
                <MenuItem key={user.userId} value={user.userId}>
                  {`${user.firstName} ${user.lastName}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            id="tags"
            options={allTags}
            freeSolo
            renderTags={(value: string[], getTagProps) =>
              value.map((option: string, index: number) => (
                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Tags"
                placeholder="Add tags"
              />
            )}
            value={formData.tags}
            onChange={handleTagChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end" gap={2}>
            <Button onClick={onCancel} variant="outlined" disabled={isSubmitting}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : (issue ? 'Update Issue' : 'Create Issue')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default IssueForm;