import React, { useState } from 'react';
import { Card, CardContent, Typography, Avatar, Chip, Box, IconButton, Badge, LinearProgress, AvatarGroup, Tooltip } from '@mui/material';
import { Edit as EditIcon, Attachment as AttachmentIcon, Comment as CommentIcon } from '@mui/icons-material';
import { Issue, User } from '../types';

interface IssueCardProps {
  issue: Issue;
  creator: User | undefined;
  onEdit: (issue: Issue) => void;
  canEdit: boolean;
  assignees: User[];
  onIssueSelect: (issue: Issue) => void; // Add this line
}

const IssueCard: React.FC<IssueCardProps> = ({ issue, creator, onEdit, canEdit, assignees, onIssueSelect }) => {
  const [showEditIcon, setShowEditIcon] = useState(false);

  const getChipColor = (value: string) => {
    const colorMap: { [key: string]: string } = {
      'Issue': '#FFB347',
      'Enhancement': '#87CEEB',
      'Open': '#ADD8E6',
      'In Process': '#E6E6FA',
      'Needs Info': '#FAFAD2',
      'Not Correct': '#FFA07A',
      'Completed': '#98FB98',
      'Blocking': '#FFC0CB',
      'Not Blocking': '#FAFAD2',
      'Design': '#A9A9A9',
      'Functionality': '#87CEFA',
      'Backend': '#DDA0DD',
    };
    return colorMap[value] || '#E0E0E0';
  };

  const stripHtml = (html: string) => {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  };

  const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + '...';
  };

  const completedTasks = issue.tasks?.filter(task => task.completed).length || 0;
  const totalTasks = issue.tasks?.length || 0;

  return (
    <Card 
      sx={{ width: '100%', position: 'relative' }}
      onMouseEnter={() => setShowEditIcon(true)}
      onMouseLeave={() => setShowEditIcon(false)}
      onClick={() => onIssueSelect(issue)}
    >
      {showEditIcon && canEdit && (
        <IconButton 
          sx={{ position: 'absolute', top: 5, right: 5 }}
          onClick={() => onEdit(issue)}
        >
          <EditIcon />
        </IconButton>
      )}
      <CardContent>
        <Typography 
          variant="h6" 
          component="div" 
          gutterBottom 
          sx={{ 
            lineHeight: '1.35rem', 
            mb: 1,
            height: '2.7rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {issue.title}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          {truncateText(stripHtml(issue.description), 110)}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <Avatar src={creator?.avatarUrl} alt={creator?.firstName} sx={{ width: 20, height: 20, mr: 1 }} />
          <Typography variant="caption" sx={{ mr: 1 }}>
            {creator ? `${creator.firstName} ${creator.lastName}` : 'Unknown'}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            Created: {new Date(issue.createdOn).toLocaleDateString()}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
          <Box display="flex" alignItems="center">
            <Typography variant="caption" sx={{ mr: 1 }}>Assigned to:</Typography>
            <AvatarGroup max={3} sx={{ '& .MuiAvatar-root': { width: 20, height: 20 } }}>
              {assignees.map((user) => (
                <Tooltip key={user.userId} title={`${user.firstName} ${user.lastName}`}>
                  <Avatar src={user.avatarUrl} alt={user.firstName} />
                </Tooltip>
              ))}
            </AvatarGroup>
          </Box>
          {totalTasks > 0 && (
            <Typography variant="caption" sx={{ color: 'green' }}>
              {completedTasks}/{totalTasks}
            </Typography>
          )}
        </Box>
        {totalTasks > 0 && (
          <Box sx={{ mt: 1, mb: 1 }}>
            <LinearProgress
              variant="determinate"
              value={(completedTasks / totalTasks) * 100}
              sx={{
                height: 10,
                borderRadius: 5,
                backgroundColor: '#e0e0e0',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                border: '1px solid #bdbdbd',
                '& .MuiLinearProgress-bar': {
                  borderRadius: 5,
                  backgroundColor: '#4caf50',
                },
              }}
            />
          </Box>
        )}
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
          <Chip label={issue.type} size="small" style={{ backgroundColor: getChipColor(issue.type) }} />
          <Chip label={issue.status} size="small" style={{ backgroundColor: getChipColor(issue.status) }} />
          <Chip label={issue.level} size="small" style={{ backgroundColor: getChipColor(issue.level) }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {issue.attachments.length > 0 && (
            <Badge
              badgeContent={issue.attachments.length}
              color="warning"
              sx={{
                mr: 1,
                '& .MuiBadge-badge': {
                  backgroundColor: 'orange',
                  color: 'white',
                },
              }}
            >
              <AttachmentIcon sx={{ color: 'gray' }} />
            </Badge>
          )}
          {issue.conversations.length > 0 && (
            <Badge
              badgeContent={issue.conversations.length}
              color="primary"
              sx={{
                '& .MuiBadge-badge': {
                  backgroundColor: '#5595d4',
                  color: 'white',
                },
              }}
            >
              <CommentIcon sx={{ color: 'gray' }} />
            </Badge>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default IssueCard;